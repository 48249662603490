import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Row from './Row';
import {useTranslation} from 'react-i18next';

export default function CollapsibleTable({rows}) {
    const {t} = useTranslation('my-events');

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>{t('schoolName')}</TableCell>
                        <TableCell>{t('mainCoordinator')}</TableCell>
                        <TableCell>{t('license')}</TableCell>
                        <TableCell>{t('action')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <Row key={`row-${index}`} event={row}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
