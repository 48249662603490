import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import BaseForm from '../../components/common/BaseForm';
import SubmitButton from '../../components/common/SubmitButton';
import {error} from '../../hoc/GlobalSnackbar/GlobalSnackbar';
import EventsService from '../../services/EventsService';
import UserService from '../../services/UserService';
import {getLanguage} from '../../utils/i18n';
import {ROUTES} from '../../utils/navigation';
import {getBaseUrl, readError} from '../../utils/utils';

export default function () {

    const {t} = useTranslation('start');
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState({});
    const [events, setEvents] = useState([]);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        Promise.all([UserService.getMyProfile(), EventsService.myEvents()])
            .then(result => {
                setUser(result[0].data);
                setEvents(result[1].data);
                setLoaded(true);
            })
            .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (loaded) {
            // if (user.accountType === 'STUDENT'){ //|| !events.length) {
            //     history.push(ROUTES.CONTEST.PARTICIPANT.FINAL_RESULTS);
            // }
            history.push(ROUTES.MY_EVENTS);
            document.location.reload();
        }
    }, [loaded]);

    const handleGoToEventsClick = () => {
        // history.push(ROUTES.MY_EVENTS);
        // document.location.reload();
        window.location.href = getBaseUrl() + ROUTES.MY_EVENTS;
    };

    const handleRegisteredSchoolsClick = () => {
        // history.push(ROUTES.CONTEST.ORGANIZER.SCHOOL_LIST);
        // document.location.reload();
        window.location.href = getBaseUrl() + ROUTES.CONTEST.ORGANIZER.SCHOOL_LIST;
    };

    const handleContestClick = () => {
        history.push(ROUTES.CONTEST.PARTICIPANT.MY_CONTEST);
        document.location.reload();
        // window.location.href = getBaseUrl() + ROUTES.CONTEST.PARTICIPANT.FINAL_RESULTS;
    };

    const handleGoToMaterials = () => {
        let url = '';
        if (getLanguage() === 'en') url = '/materials';
        if (getLanguage() === 'pl') url = '/pl/materialy';
        if (getLanguage() === 'ru') url = '/ru/материалы';
        if (getLanguage() === 'es') url = '/es/materiales';
        window.location.href = url;
    };

    return (
        <>
            {
                loading ? <CircularProgress/> :
                    <BaseForm size="lg" Icon={AccountTreeIcon} title={t('title')}>
                        <br></br>
                        <Grid container spacing={1} style={{justifyContent: 'center'}}>
                            <Grid item xs={9}>
                                <Paper elevation={0}>
                                    <SubmitButton onClick={handleGoToMaterials}>
                                        {t('materialButton')}
                                    </SubmitButton>
                                </Paper>
                            </Grid>
                            <Grid item xs={9}>
                                <Paper elevation={0}>
                                    <SubmitButton onClick={handleGoToEventsClick}>
                                        {t('eventsButton')}
                                    </SubmitButton>
                                </Paper>
                            </Grid>
                            {/*<Grid item xs={9}>*/}
                            {/*    <Paper elevation={0}>*/}
                            {/*        <SubmitButton onClick={handleRegisteredSchoolsClick} color='secondary'>*/}
                            {/*            {t('registeredSchoolsButton')}*/}
                            {/*        </SubmitButton>*/}
                            {/*    </Paper>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={9}>*/}
                            {/*    <Paper elevation={0}>*/}
                            {/*        <SubmitButton onClick={handleContestClick}>*/}
                            {/*            {t('contestButton')}*/}
                            {/*        </SubmitButton>*/}
                            {/*    </Paper>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </BaseForm>
            }
        </>
    );
}
