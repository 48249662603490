import React, {useEffect, useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getLanguage} from '../../utils/i18n';
import TextFieldComponent from '../../components/common/TextFieldComponent';

export default function Position({name, label, form, ...rest}) {
    const [positions, setPositions] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        fetch(`/assets/i18n/event-details/positions/${getLanguage()}.json`)
            .then(response => response.json())
            .then(data => setPositions(data))
            .catch((error) => setOptions(['There was an error while fetching the data']));
    }, []);

    useEffect(() => {
        const selected = form.watch(name);
        const optionsToSet = [...positions];
        if (selected && optionsToSet.indexOf(selected) === -1) {
            optionsToSet.push(selected);
        }
        setOptions(optionsToSet);
        form.register({'name': name});
        if (!!selected) {
            setSelectedOption(selected);
            form.setValue(name, selected, {shouldValidate: true});
        } else {
            setSelectedOption(optionsToSet[0]);
            form.setValue(name, optionsToSet[0], {shouldValidate: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positions]);

    const handleChange = (event, newValue) => {
        form.setValue(name, newValue || event.target.value, {shouldValidate: true});
    };

    return (
        (options.length > 0) ?
            <Autocomplete
                id={name}
                {...rest}
                defaultValue={selectedOption}
                options={options}
                onChange={handleChange}
                selectOnFocus
                handleHomeEndKeys
                freeSolo
                renderInput={(params) => (
                    <TextFieldComponent {...params}
                                        name={name + 'Ignore'}
                                        onChange={handleChange}
                                        label={label}
                                        required
                                        form={form}
                    />
                )}
            /> : null
    );
}

