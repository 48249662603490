import React, {useState} from "react";
import BaseForm from "../../components/common/BaseForm";
import TimerIcon from '@material-ui/icons/Timer';
import {useTranslation} from "react-i18next";
import SubmitButton from "../../components/common/SubmitButton";
import ExamOnlineService from "../../services/ExamOnlineService";
import {useSnackbar} from "notistack";
import {readError} from "../../utils/utils";
import {error} from "../../hoc/GlobalSnackbar/GlobalSnackbar";
import ExamResults from "./ExamResults";
import CircularProgress from "@material-ui/core/CircularProgress";
import QuizForm from "./QuizForm";

export default function () {

    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [finished, setFinished] = useState(false);
    const [tasks, setTasks] = useState([])
    const [answersFromApi, setAnswersFromApi] = useState([])
    const [verifiedResults, setVerifiedResults] = useState({});
    const [attemptStartTime, setAttemptStartTime] = useState(null);
    const [attemptSeconds, setAttemptSeconds] = useState(0);
    const [isTimeIsUp, setIsTimeIsUp] = useState(false);
    const {t} = useTranslation('exam-online');

    const handleStart = () => {
        setLoading(true);
        ExamOnlineService.getQuestions()
            .then(response => {
                setTasks(response.data.tasks);
                setAnswersFromApi(response.data.answers);
                startTimer();
            })
            .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
            .finally(() => setLoading(false));
    };

    const startTimer = () => {
        setAttemptStartTime(new Date());
        const date = new Date();
        date.setSeconds(date.getSeconds() + 60);
        setEndDate(date);
    };

    const handleFinished = (results, timeIsUp = false) => {
        setIsTimeIsUp(timeIsUp)
        setFinished(true);
        const endTime = new Date();
        let timeDiff = endTime - attemptStartTime; //in ms
        timeDiff = Math.round(timeDiff /= 1000);
        setAttemptSeconds(timeDiff);
        setLoading(true);
        ExamOnlineService.sendAnswers({...results, time: timeDiff, answers: answersFromApi})
            .then(response => {
                setVerifiedResults(response.data);
            })
            .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <BaseForm Icon={TimerIcon} title={t('title')} size='md'>
                {
                    !endDate ?
                        <>
                            <p style={{margin: '20px 0px', textAlign: 'center'}}>{t('entryText')}</p>
                            {/*<TextFieldComponent name='fullName' form={form}/>*/}
                            {/*<EmailFieldComponent name='email' form={form}/>*/}
                            <SubmitButton color='secondary' loading={loading} onClick={handleStart}>
                                {t('startButton')}
                            </SubmitButton>
                        </> :
                        !finished ?
                            <QuizForm tasks={tasks} endDate={endDate} t={t} finished={handleFinished}/> :
                            loading ? <CircularProgress/> :
                                <ExamResults t={t} resultList={verifiedResults} time={attemptSeconds}
                                             timeIsUp={isTimeIsUp}/>
                }
            </BaseForm>
        </>
    );
}
