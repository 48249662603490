import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useStyles} from '../../utils/styling';
import {useTranslation} from 'react-i18next';
import {userAuthenticated} from '../../services/auth';
import TextFieldComponent from '../../components/common/TextFieldComponent';
import EmailFieldComponent from '../../components/common/EmailFieldComponent';
import SubmitButton from '../../components/common/SubmitButton';
import {readError} from '../../utils/utils';
import {useSnackbar} from "notistack";
import {error} from "../../hoc/GlobalSnackbar/GlobalSnackbar";
import UserService from "../../services/UserService";

export default function ({onSignInCallback}) {
    const classes = useStyles();
    const form = useForm();
    const {t} = useTranslation('sign-in');
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);

    const onSignIn = (response) => {
        const authorization = response.headers.authorization;
        if (!!authorization) {
            userAuthenticated(authorization);
            onSignInCallback();
        }
    };

    const onSubmit = data => {
        setLoading(true);
        UserService.signIn(data)
            .then(response => onSignIn(response))
            .catch(reason => {
                const err = readError(reason);
                if (err.code === '403') {
                    form.setError('email', {
                        message: err.statusText
                    });
                } else {
                    enqueueSnackbar(err?.statusText, error);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <form className={classes.form} noValidate onSubmit={form.handleSubmit(onSubmit)}>
            <EmailFieldComponent
                name="email"
                required
                autoFocus
                form={form}
            />
            <TextFieldComponent
                name="password"
                type="password"
                autoComplete="current-password"
                required
                form={form}
            />
            <SubmitButton classes={classes} loading={loading}>
                {t('submit')}
            </SubmitButton>
        </form>
    );
}
