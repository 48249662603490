const MIN_PASSWORD_LENGTH = 8;

export function validatePassword(password, repeatPassword, t, form) {
    if (password && password.length < MIN_PASSWORD_LENGTH) {
        form.setError('password', {
            type: 'invalidPassword',
            message: t('passwordValidation', {characters: MIN_PASSWORD_LENGTH})
        });
        return false;
    }
    if (password !== repeatPassword) {
        form.setError('repeatPassword', {
            type: 'passwordNotMatch',
            message: t('invalidRepeatPassword')
        });
        return false;
    }
    return true;
}
