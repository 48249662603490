import {apiService, ENDPOINTS} from "./api";

const myContestCall = () => {
  return apiService.get(ENDPOINTS.CONTEST.MY);
};

const getStartTimeCall = () => {
  return apiService.get(ENDPOINTS.CONTEST.START_TIME);
};

const getCurrentTimeCall = () => {
  return apiService.get(ENDPOINTS.CONTEST.CURRENT_TIME);
};

const byAccessCodeCall = (accessCode) => {
  return apiService.get(ENDPOINTS.CONTEST.SCHOOL_BY_CODE(accessCode));
};

const registerSchoolCall = (event) => {
  return apiService.post(ENDPOINTS.CONTEST.REGISTER_SCHOOL, event);
};

const registerParticipantCall = (accessCode) => {
  return apiService.post(ENDPOINTS.CONTEST.REGISTER_PARTICIPANT(accessCode));
};

const registerNewAccountParticipantCall = (accessCode, user) => {
  return apiService.post(ENDPOINTS.CONTEST.REGISTER_NEW_ACCOUNT_PARTICIPANT(accessCode), user);
};

const getCategoriesCall = (eventId) => {
  return apiService.get(ENDPOINTS.CONTEST.SCHOOL_CATEGORIES(eventId));
};

const getMyAnswersCall = (questionId) => {
  return apiService.get(ENDPOINTS.CONTEST.ANSWERS(questionId));
};

const saveMyAnswersCall = (questionId, data) => {
  return apiService.post(ENDPOINTS.CONTEST.ANSWERS(questionId), data);
};

const calculateResultsCall = () => {
  return apiService.get(ENDPOINTS.CONTEST.ADMIN.CALCULATE_RESULTS);
};

const getMyResultsCall = () => {
  return apiService.get(ENDPOINTS.CONTEST.MY_RESULTS);
};

const getFinalResultsCall = () => {
  return apiService.get(ENDPOINTS.CONTEST.FINAL_RESULTS);
};

const getResultsByCategoryCall = (categoryId) => {
  return apiService.get(ENDPOINTS.CONTEST.RESULTS_BY_CATEGORY(categoryId));
};

const ContestService = {
  myContest: myContestCall,
  getStartTime: getStartTimeCall,
  getCurrentTime: getCurrentTimeCall,
  registerSchool: registerSchoolCall,
  registerParticipant: registerParticipantCall,
  registerNewAccountParticipant: registerNewAccountParticipantCall,
  getCategories: getCategoriesCall,
  byAccessCode: byAccessCodeCall,
  getMyAnswers: getMyAnswersCall,
  saveMyAnswers: saveMyAnswersCall,
  myResults: getMyResultsCall,
  finalResults: getFinalResultsCall,
  calculateResults: calculateResultsCall,
  getResultsByCategory: getResultsByCategoryCall
}

export default ContestService;
