import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

export default function SubmitButton({classes={}, loading, children, color='primary', ...rest}) {

    return (
        <Button
            type="submit"
            variant="contained"
            color={color}
            className={classes?.submit}
            disabled={loading}
            {...rest}
        >
            {children}
            {loading ?
                <CircularProgress size={24}/>
                : null
            }
        </Button>
    );
}
