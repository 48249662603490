import {apiService, ENDPOINTS} from './api';

const getQuestionsCall = () => {
    return apiService.get(ENDPOINTS.EXAM.QUESTIONS);
};

const sendAnswersCall = (data) => {
    return apiService.post(ENDPOINTS.EXAM.QUESTIONS, data);
};


const ExamOnlineService = {
    getQuestions: getQuestionsCall,
    sendAnswers: sendAnswersCall
};

export default ExamOnlineService;
