import TextField from '@material-ui/core/TextField';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {get} from 'react-hook-form';

export default function TextFieldComponent({name, required = false, validationRegexp, validationRegexpMessage, form, ...rest}) {

    const {t} = useTranslation();

    const error = form ? get(form.errors, name) : undefined;
    const getErrorMessage = (error) => {
        if (error && error.type === 'required') {
            return t('requiredValidationMessage');
        } else if (error && error.type === 'pattern') {
            return validationRegexpMessage;
        } else if (error) {
            return error.message;
        }
    };

    const conf = {
        'required': required
    }

    if (validationRegexp) {
        conf.pattern = validationRegexp
    }

    return (
        <TextField
            id={name}
            name={name}
            label={t(name)}
            required={required}
            error={error !== undefined}
            helperText={getErrorMessage(error)}
            inputRef={form ? form.register(conf) : null}
            {...rest}
        />
    );
}
