import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import SchoolIcon from '@material-ui/icons/School';
import {ROUTES} from '../../utils/navigation';

import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CollapsibleTable from './CollapsibleTable';
import BaseForm from '../../components/common/BaseForm';
import {useStyles} from '../../utils/styling';
import {useHistory} from 'react-router-dom';
import {readError} from '../../utils/utils';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {useTranslation} from 'react-i18next';
import Errors from '../../components/common/Errors';
import EventsService from "../../services/EventsService";
import PreviousSchoolsDialog from "./PreviousSchoolsDialog";

export default function () {
  const classes = useStyles();
  const {t} = useTranslation('my-events');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [events, setEvents] = useState();
  const [previousYearSchools, setPreviousYearSchools] = useState();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (school) => {
    sessionStorage.setItem('PREVIOUS_YEAR_SCHOOL', JSON.stringify(school));
    history.push(ROUTES.REGISTER_SCHOOL);
  }

  useEffect(() => {
    EventsService.myEvents()
      .then(result => setEvents(result.data))
      .catch(reason => setError(readError(reason)))
      .finally(() => setLoading(false));
    EventsService.myEventsFromPreviousYear(2023)
      .then(result => setPreviousYearSchools(result.data))
      .catch(reason => setError(readError(reason)))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {
        loading ? <CircularProgress/> :
          <>
            <BaseForm size="lg" Icon={AssignmentIcon} title={t('title')}>
              {error ? <Errors error={error} onClose={() => setError(undefined)}/>
                : null}
              {(events && events.length) ?
                <CollapsibleTable rows={events}/>
                :
                <h3>{t('noEvents')}</h3>}
            </BaseForm>
            <br></br>
            {/*TODO here you can enable registration button*/}
            {/* Conditionally render the Container */}
            {/*{false && (*/}
            {/*  <Container maxWidth="sm">*/}
            {/*    <Button*/}
            {/*      startIcon={<SchoolIcon/>}*/}
            {/*      type="submit"*/}
            {/*      variant="contained"*/}
            {/*      color="secondary"*/}
            {/*      className={classes.submit}*/}
            {/*      onClick={() => history.push(ROUTES.REGISTER_SCHOOL)}*/}
            {/*    >*/}
            {/*      {(events && events.length) ?*/}
            {/*        t('registerAnotherSchoolButton')*/}
            {/*        :*/}
            {/*        t('registerSchoolButton')}*/}
            {/*    </Button>*/}
            {/*    {previousYearSchools && previousYearSchools.length > 0 ?*/}
            {/*      <>*/}
            {/*        <Button*/}
            {/*          startIcon={<SchoolIcon/>}*/}
            {/*          type="submit"*/}
            {/*          variant="contained"*/}
            {/*          color="primary"*/}
            {/*          className={classes.submit}*/}
            {/*          onClick={handleClickOpen}*/}
            {/*        >*/}
            {/*          {t('registerSchoolFromPreviousYearButton')}*/}
            {/*        </Button>*/}
            {/*        <p><i>{t('registerSchoolFromPreviousYearInfo')}</i></p>*/}
            {/*      </> : null*/}
            {/*    }*/}
            {/*  </Container>*/}
            {/*)}e*/}
            <PreviousSchoolsDialog open={open} handleClose={handleClose} schools={previousYearSchools} onSelect={handleSelect}/>
          </>
      }
    </>
  );
}
