// https://github.com/umpirsky/country-list/tree/master/data

export const countries_en = [
    {
        'code': 'AF',
        'label': 'Afghanistan'
    },
    {
        'code': 'AX',
        'label': 'Åland Islands'
    },
    {
        'code': 'AL',
        'label': 'Albania'
    },
    {
        'code': 'DZ',
        'label': 'Algeria'
    },
    {
        'code': 'AS',
        'label': 'American Samoa'
    },
    {
        'code': 'AD',
        'label': 'Andorra'
    },
    {
        'code': 'AO',
        'label': 'Angola'
    },
    {
        'code': 'AI',
        'label': 'Anguilla'
    },
    {
        'code': 'AQ',
        'label': 'Antarctica'
    },
    {
        'code': 'AG',
        'label': 'Antigua & Barbuda'
    },
    {
        'code': 'AR',
        'label': 'Argentina'
    },
    {
        'code': 'AM',
        'label': 'Armenia'
    },
    {
        'code': 'AW',
        'label': 'Aruba'
    },
    {
        'code': 'AU',
        'label': 'Australia'
    },
    {
        'code': 'AT',
        'label': 'Austria'
    },
    {
        'code': 'AZ',
        'label': 'Azerbaijan'
    },
    {
        'code': 'BS',
        'label': 'Bahamas'
    },
    {
        'code': 'BH',
        'label': 'Bahrain'
    },
    {
        'code': 'BD',
        'label': 'Bangladesh'
    },
    {
        'code': 'BB',
        'label': 'Barbados'
    },
    {
        'code': 'BY',
        'label': 'Belarus'
    },
    {
        'code': 'BE',
        'label': 'Belgium'
    },
    {
        'code': 'BZ',
        'label': 'Belize'
    },
    {
        'code': 'BJ',
        'label': 'Benin'
    },
    {
        'code': 'BM',
        'label': 'Bermuda'
    },
    {
        'code': 'BT',
        'label': 'Bhutan'
    },
    {
        'code': 'BO',
        'label': 'Bolivia'
    },
    {
        'code': 'BA',
        'label': 'Bosnia & Herzegovina'
    },
    {
        'code': 'BW',
        'label': 'Botswana'
    },
    {
        'code': 'BV',
        'label': 'Bouvet Island'
    },
    {
        'code': 'BR',
        'label': 'Brazil'
    },
    {
        'code': 'IO',
        'label': 'British Indian Ocean Territory'
    },
    {
        'code': 'VG',
        'label': 'British Virgin Islands'
    },
    {
        'code': 'BN',
        'label': 'Brunei'
    },
    {
        'code': 'BG',
        'label': 'Bulgaria'
    },
    {
        'code': 'BF',
        'label': 'Burkina Faso'
    },
    {
        'code': 'BI',
        'label': 'Burundi'
    },
    {
        'code': 'KH',
        'label': 'Cambodia'
    },
    {
        'code': 'CM',
        'label': 'Cameroon'
    },
    {
        'code': 'CA',
        'label': 'Canada'
    },
    {
        'code': 'CV',
        'label': 'Cape Verde'
    },
    {
        'code': 'BQ',
        'label': 'Caribbean Netherlands'
    },
    {
        'code': 'KY',
        'label': 'Cayman Islands'
    },
    {
        'code': 'CF',
        'label': 'Central African Republic'
    },
    {
        'code': 'TD',
        'label': 'Chad'
    },
    {
        'code': 'CL',
        'label': 'Chile'
    },
    {
        'code': 'CN',
        'label': 'China'
    },
    {
        'code': 'CX',
        'label': 'Christmas Island'
    },
    {
        'code': 'CC',
        'label': 'Cocos (Keeling) Islands'
    },
    {
        'code': 'CO',
        'label': 'Colombia'
    },
    {
        'code': 'KM',
        'label': 'Comoros'
    },
    {
        'code': 'CG',
        'label': 'Congo - Brazzaville'
    },
    {
        'code': 'CD',
        'label': 'Congo - Kinshasa'
    },
    {
        'code': 'CK',
        'label': 'Cook Islands'
    },
    {
        'code': 'CR',
        'label': 'Costa Rica'
    },
    {
        'code': 'CI',
        'label': 'Côte d’Ivoire'
    },
    {
        'code': 'HR',
        'label': 'Croatia'
    },
    {
        'code': 'CU',
        'label': 'Cuba'
    },
    {
        'code': 'CW',
        'label': 'Curaçao'
    },
    {
        'code': 'CY',
        'label': 'Cyprus'
    },
    {
        'code': 'CZ',
        'label': 'Czechia'
    },
    {
        'code': 'DK',
        'label': 'Denmark'
    },
    {
        'code': 'DJ',
        'label': 'Djibouti'
    },
    {
        'code': 'DM',
        'label': 'Dominica'
    },
    {
        'code': 'DO',
        'label': 'Dominican Republic'
    },
    {
        'code': 'EC',
        'label': 'Ecuador'
    },
    {
        'code': 'EG',
        'label': 'Egypt'
    },
    {
        'code': 'SV',
        'label': 'El Salvador'
    },
    {
        'code': 'GQ',
        'label': 'Equatorial Guinea'
    },
    {
        'code': 'ER',
        'label': 'Eritrea'
    },
    {
        'code': 'EE',
        'label': 'Estonia'
    },
    {
        'code': 'SZ',
        'label': 'Eswatini'
    },
    {
        'code': 'ET',
        'label': 'Ethiopia'
    },
    {
        'code': 'FK',
        'label': 'Falkland Islands'
    },
    {
        'code': 'FO',
        'label': 'Faroe Islands'
    },
    {
        'code': 'FJ',
        'label': 'Fiji'
    },
    {
        'code': 'FI',
        'label': 'Finland'
    },
    {
        'code': 'FR',
        'label': 'France'
    },
    {
        'code': 'GF',
        'label': 'French Guiana'
    },
    {
        'code': 'PF',
        'label': 'French Polynesia'
    },
    {
        'code': 'TF',
        'label': 'French Southern Territories'
    },
    {
        'code': 'GA',
        'label': 'Gabon'
    },
    {
        'code': 'GM',
        'label': 'Gambia'
    },
    {
        'code': 'GE',
        'label': 'Georgia'
    },
    {
        'code': 'DE',
        'label': 'Germany'
    },
    {
        'code': 'GH',
        'label': 'Ghana'
    },
    {
        'code': 'GI',
        'label': 'Gibraltar'
    },
    {
        'code': 'GR',
        'label': 'Greece'
    },
    {
        'code': 'GL',
        'label': 'Greenland'
    },
    {
        'code': 'GD',
        'label': 'Grenada'
    },
    {
        'code': 'GP',
        'label': 'Guadeloupe'
    },
    {
        'code': 'GU',
        'label': 'Guam'
    },
    {
        'code': 'GT',
        'label': 'Guatemala'
    },
    {
        'code': 'GG',
        'label': 'Guernsey'
    },
    {
        'code': 'GN',
        'label': 'Guinea'
    },
    {
        'code': 'GW',
        'label': 'Guinea-Bissau'
    },
    {
        'code': 'GY',
        'label': 'Guyana'
    },
    {
        'code': 'HT',
        'label': 'Haiti'
    },
    {
        'code': 'HM',
        'label': 'Heard & McDonald Islands'
    },
    {
        'code': 'HN',
        'label': 'Honduras'
    },
    {
        'code': 'HK',
        'label': 'Hong Kong SAR China'
    },
    {
        'code': 'HU',
        'label': 'Hungary'
    },
    {
        'code': 'IS',
        'label': 'Iceland'
    },
    {
        'code': 'IN',
        'label': 'India'
    },
    {
        'code': 'ID',
        'label': 'Indonesia'
    },
    {
        'code': 'IR',
        'label': 'Iran'
    },
    {
        'code': 'IQ',
        'label': 'Iraq'
    },
    {
        'code': 'IE',
        'label': 'Ireland'
    },
    {
        'code': 'IM',
        'label': 'Isle of Man'
    },
    {
        'code': 'IL',
        'label': 'Israel'
    },
    {
        'code': 'IT',
        'label': 'Italy'
    },
    {
        'code': 'JM',
        'label': 'Jamaica'
    },
    {
        'code': 'JP',
        'label': 'Japan'
    },
    {
        'code': 'JE',
        'label': 'Jersey'
    },
    {
        'code': 'JO',
        'label': 'Jordan'
    },
    {
        'code': 'KZ',
        'label': 'Kazakhstan'
    },
    {
        'code': 'KE',
        'label': 'Kenya'
    },
    {
        'code': 'KI',
        'label': 'Kiribati'
    },
    {
        'code': 'KW',
        'label': 'Kuwait'
    },
    {
        'code': 'KG',
        'label': 'Kyrgyzstan'
    },
    {
        'code': 'LA',
        'label': 'Laos'
    },
    {
        'code': 'LV',
        'label': 'Latvia'
    },
    {
        'code': 'LB',
        'label': 'Lebanon'
    },
    {
        'code': 'LS',
        'label': 'Lesotho'
    },
    {
        'code': 'LR',
        'label': 'Liberia'
    },
    {
        'code': 'LY',
        'label': 'Libya'
    },
    {
        'code': 'LI',
        'label': 'Liechtenstein'
    },
    {
        'code': 'LT',
        'label': 'Lithuania'
    },
    {
        'code': 'LU',
        'label': 'Luxembourg'
    },
    {
        'code': 'MO',
        'label': 'Macao SAR China'
    },
    {
        'code': 'MG',
        'label': 'Madagascar'
    },
    {
        'code': 'MW',
        'label': 'Malawi'
    },
    {
        'code': 'MY',
        'label': 'Malaysia'
    },
    {
        'code': 'MV',
        'label': 'Maldives'
    },
    {
        'code': 'ML',
        'label': 'Mali'
    },
    {
        'code': 'MT',
        'label': 'Malta'
    },
    {
        'code': 'MH',
        'label': 'Marshall Islands'
    },
    {
        'code': 'MQ',
        'label': 'Martinique'
    },
    {
        'code': 'MR',
        'label': 'Mauritania'
    },
    {
        'code': 'MU',
        'label': 'Mauritius'
    },
    {
        'code': 'YT',
        'label': 'Mayotte'
    },
    {
        'code': 'MX',
        'label': 'Mexico'
    },
    {
        'code': 'FM',
        'label': 'Micronesia'
    },
    {
        'code': 'MD',
        'label': 'Moldova'
    },
    {
        'code': 'MC',
        'label': 'Monaco'
    },
    {
        'code': 'MN',
        'label': 'Mongolia'
    },
    {
        'code': 'ME',
        'label': 'Montenegro'
    },
    {
        'code': 'MS',
        'label': 'Montserrat'
    },
    {
        'code': 'MA',
        'label': 'Morocco'
    },
    {
        'code': 'MZ',
        'label': 'Mozambique'
    },
    {
        'code': 'MM',
        'label': 'Myanmar (Burma)'
    },
    {
        'code': 'NA',
        'label': 'Namibia'
    },
    {
        'code': 'NR',
        'label': 'Nauru'
    },
    {
        'code': 'NP',
        'label': 'Nepal'
    },
    {
        'code': 'NL',
        'label': 'Netherlands'
    },
    {
        'code': 'NC',
        'label': 'New Caledonia'
    },
    {
        'code': 'NZ',
        'label': 'New Zealand'
    },
    {
        'code': 'NI',
        'label': 'Nicaragua'
    },
    {
        'code': 'NE',
        'label': 'Niger'
    },
    {
        'code': 'NG',
        'label': 'Nigeria'
    },
    {
        'code': 'NU',
        'label': 'Niue'
    },
    {
        'code': 'NF',
        'label': 'Norfolk Island'
    },
    {
        'code': 'KP',
        'label': 'North Korea'
    },
    {
        'code': 'MK',
        'label': 'North Macedonia'
    },
    {
        'code': 'MP',
        'label': 'Northern Mariana Islands'
    },
    {
        'code': 'NO',
        'label': 'Norway'
    },
    {
        'code': 'OM',
        'label': 'Oman'
    },
    {
        'code': 'PK',
        'label': 'Pakistan'
    },
    {
        'code': 'PW',
        'label': 'Palau'
    },
    {
        'code': 'PS',
        'label': 'Palestinian Territories'
    },
    {
        'code': 'PA',
        'label': 'Panama'
    },
    {
        'code': 'PG',
        'label': 'Papua New Guinea'
    },
    {
        'code': 'PY',
        'label': 'Paraguay'
    },
    {
        'code': 'PE',
        'label': 'Peru'
    },
    {
        'code': 'PH',
        'label': 'Philippines'
    },
    {
        'code': 'PN',
        'label': 'Pitcairn Islands'
    },
    {
        'code': 'PL',
        'label': 'Poland'
    },
    {
        'code': 'PT',
        'label': 'Portugal'
    },
    {
        'code': 'PR',
        'label': 'Puerto Rico'
    },
    {
        'code': 'QA',
        'label': 'Qatar'
    },
    {
        'code': 'RE',
        'label': 'Réunion'
    },
    {
        'code': 'RO',
        'label': 'Romania'
    },
    // {
    //     'code': 'RU',
    //     'label': 'Russia'
    // },
    {
        'code': 'RW',
        'label': 'Rwanda'
    },
    {
        'code': 'WS',
        'label': 'Samoa'
    },
    {
        'code': 'SM',
        'label': 'San Marino'
    },
    {
        'code': 'ST',
        'label': 'São Tomé & Príncipe'
    },
    {
        'code': 'SA',
        'label': 'Saudi Arabia'
    },
    {
        'code': 'SN',
        'label': 'Senegal'
    },
    {
        'code': 'RS',
        'label': 'Serbia'
    },
    {
        'code': 'SC',
        'label': 'Seychelles'
    },
    {
        'code': 'SL',
        'label': 'Sierra Leone'
    },
    {
        'code': 'SG',
        'label': 'Singapore'
    },
    {
        'code': 'SX',
        'label': 'Sint Maarten'
    },
    {
        'code': 'SK',
        'label': 'Slovakia'
    },
    {
        'code': 'SI',
        'label': 'Slovenia'
    },
    {
        'code': 'SB',
        'label': 'Solomon Islands'
    },
    {
        'code': 'SO',
        'label': 'Somalia'
    },
    {
        'code': 'ZA',
        'label': 'South Africa'
    },
    {
        'code': 'GS',
        'label': 'South Georgia & South Sandwich Islands'
    },
    {
        'code': 'KR',
        'label': 'South Korea'
    },
    {
        'code': 'SS',
        'label': 'South Sudan'
    },
    {
        'code': 'ES',
        'label': 'Spain'
    },
    {
        'code': 'LK',
        'label': 'Sri Lanka'
    },
    {
        'code': 'BL',
        'label': 'St. Barthélemy'
    },
    {
        'code': 'SH',
        'label': 'St. Helena'
    },
    {
        'code': 'KN',
        'label': 'St. Kitts & Nevis'
    },
    {
        'code': 'LC',
        'label': 'St. Lucia'
    },
    {
        'code': 'MF',
        'label': 'St. Martin'
    },
    {
        'code': 'PM',
        'label': 'St. Pierre & Miquelon'
    },
    {
        'code': 'VC',
        'label': 'St. Vincent & Grenadines'
    },
    {
        'code': 'SD',
        'label': 'Sudan'
    },
    {
        'code': 'SR',
        'label': 'Suriname'
    },
    {
        'code': 'SJ',
        'label': 'Svalbard & Jan Mayen'
    },
    {
        'code': 'SE',
        'label': 'Sweden'
    },
    {
        'code': 'CH',
        'label': 'Switzerland'
    },
    {
        'code': 'SY',
        'label': 'Syria'
    },
    {
        'code': 'TW',
        'label': 'Taiwan'
    },
    {
        'code': 'TJ',
        'label': 'Tajikistan'
    },
    {
        'code': 'TZ',
        'label': 'Tanzania'
    },
    {
        'code': 'TH',
        'label': 'Thailand'
    },
    {
        'code': 'TL',
        'label': 'Timor-Leste'
    },
    {
        'code': 'TG',
        'label': 'Togo'
    },
    {
        'code': 'TK',
        'label': 'Tokelau'
    },
    {
        'code': 'TO',
        'label': 'Tonga'
    },
    {
        'code': 'TT',
        'label': 'Trinidad & Tobago'
    },
    {
        'code': 'TN',
        'label': 'Tunisia'
    },
    {
        'code': 'TR',
        'label': 'Turkey'
    },
    {
        'code': 'TM',
        'label': 'Turkmenistan'
    },
    {
        'code': 'TC',
        'label': 'Turks & Caicos Islands'
    },
    {
        'code': 'TV',
        'label': 'Tuvalu'
    },
    {
        'code': 'UM',
        'label': 'U.S. Outlying Islands'
    },
    {
        'code': 'VI',
        'label': 'U.S. Virgin Islands'
    },
    {
        'code': 'UG',
        'label': 'Uganda'
    },
    {
        'code': 'UA',
        'label': 'Ukraine'
    },
    {
        'code': 'AE',
        'label': 'United Arab Emirates'
    },
    {
        'code': 'GB',
        'label': 'United Kingdom'
    },
    {
        'code': 'US',
        'label': 'United States'
    },
    {
        'code': 'UY',
        'label': 'Uruguay'
    },
    {
        'code': 'UZ',
        'label': 'Uzbekistan'
    },
    {
        'code': 'VU',
        'label': 'Vanuatu'
    },
    {
        'code': 'VA',
        'label': 'Vatican City'
    },
    {
        'code': 'VE',
        'label': 'Venezuela'
    },
    {
        'code': 'VN',
        'label': 'Vietnam'
    },
    {
        'code': 'WF',
        'label': 'Wallis & Futuna'
    },
    {
        'code': 'EH',
        'label': 'Western Sahara'
    },
    {
        'code': 'YE',
        'label': 'Yemen'
    },
    {
        'code': 'ZM',
        'label': 'Zambia'
    },
    {
        'code': 'ZW',
        'label': 'Zimbabwe'
    }
];
export const countries_pl = [
    {
        "code": "AF",
        "label": "Afganistan"
    },
    {
        "code": "AL",
        "label": "Albania"
    },
    {
        "code": "DZ",
        "label": "Algieria"
    },
    {
        "code": "AD",
        "label": "Andora"
    },
    {
        "code": "AO",
        "label": "Angola"
    },
    {
        "code": "AI",
        "label": "Anguilla"
    },
    {
        "code": "AQ",
        "label": "Antarktyda"
    },
    {
        "code": "AG",
        "label": "Antigua i Barbuda"
    },
    {
        "code": "SA",
        "label": "Arabia Saudyjska"
    },
    {
        "code": "AR",
        "label": "Argentyna"
    },
    {
        "code": "AM",
        "label": "Armenia"
    },
    {
        "code": "AW",
        "label": "Aruba"
    },
    {
        "code": "AU",
        "label": "Australia"
    },
    {
        "code": "AT",
        "label": "Austria"
    },
    {
        "code": "AZ",
        "label": "Azerbejdżan"
    },
    {
        "code": "BS",
        "label": "Bahamy"
    },
    {
        "code": "BH",
        "label": "Bahrajn"
    },
    {
        "code": "BD",
        "label": "Bangladesz"
    },
    {
        "code": "BB",
        "label": "Barbados"
    },
    {
        "code": "BE",
        "label": "Belgia"
    },
    {
        "code": "BZ",
        "label": "Belize"
    },
    {
        "code": "BJ",
        "label": "Benin"
    },
    {
        "code": "BM",
        "label": "Bermudy"
    },
    {
        "code": "BT",
        "label": "Bhutan"
    },
    {
        "code": "BY",
        "label": "Białoruś"
    },
    {
        "code": "BO",
        "label": "Boliwia"
    },
    {
        "code": "BA",
        "label": "Bośnia i Hercegowina"
    },
    {
        "code": "BW",
        "label": "Botswana"
    },
    {
        "code": "BR",
        "label": "Brazylia"
    },
    {
        "code": "BN",
        "label": "Brunei"
    },
    {
        "code": "IO",
        "label": "Brytyjskie Terytorium Oceanu Indyjskiego"
    },
    {
        "code": "VG",
        "label": "Brytyjskie Wyspy Dziewicze"
    },
    {
        "code": "BG",
        "label": "Bułgaria"
    },
    {
        "code": "BF",
        "label": "Burkina Faso"
    },
    {
        "code": "BI",
        "label": "Burundi"
    },
    {
        "code": "CL",
        "label": "Chile"
    },
    {
        "code": "CN",
        "label": "Chiny"
    },
    {
        "code": "HR",
        "label": "Chorwacja"
    },
    {
        "code": "CI",
        "label": "Côte d’Ivoire"
    },
    {
        "code": "CW",
        "label": "Curaçao"
    },
    {
        "code": "CY",
        "label": "Cypr"
    },
    {
        "code": "TD",
        "label": "Czad"
    },
    {
        "code": "ME",
        "label": "Czarnogóra"
    },
    {
        "code": "CZ",
        "label": "Czechy"
    },
    {
        "code": "UM",
        "label": "Dalekie Wyspy Mniejsze Stanów Zjednoczonych"
    },
    {
        "code": "DK",
        "label": "Dania"
    },
    {
        "code": "CD",
        "label": "Demokratyczna Republika Konga"
    },
    {
        "code": "DM",
        "label": "Dominika"
    },
    {
        "code": "DO",
        "label": "Dominikana"
    },
    {
        "code": "DJ",
        "label": "Dżibuti"
    },
    {
        "code": "EG",
        "label": "Egipt"
    },
    {
        "code": "EC",
        "label": "Ekwador"
    },
    {
        "code": "ER",
        "label": "Erytrea"
    },
    {
        "code": "EE",
        "label": "Estonia"
    },
    {
        "code": "SZ",
        "label": "Eswatini"
    },
    {
        "code": "ET",
        "label": "Etiopia"
    },
    {
        "code": "FK",
        "label": "Falklandy"
    },
    {
        "code": "FJ",
        "label": "Fidżi"
    },
    {
        "code": "PH",
        "label": "Filipiny"
    },
    {
        "code": "FI",
        "label": "Finlandia"
    },
    {
        "code": "FR",
        "label": "Francja"
    },
    {
        "code": "TF",
        "label": "Francuskie Terytoria Południowe i Antarktyczne"
    },
    {
        "code": "GA",
        "label": "Gabon"
    },
    {
        "code": "GM",
        "label": "Gambia"
    },
    {
        "code": "GS",
        "label": "Georgia Południowa i Sandwich Południowy"
    },
    {
        "code": "GH",
        "label": "Ghana"
    },
    {
        "code": "GI",
        "label": "Gibraltar"
    },
    {
        "code": "GR",
        "label": "Grecja"
    },
    {
        "code": "GD",
        "label": "Grenada"
    },
    {
        "code": "GL",
        "label": "Grenlandia"
    },
    {
        "code": "GE",
        "label": "Gruzja"
    },
    {
        "code": "GU",
        "label": "Guam"
    },
    {
        "code": "GG",
        "label": "Guernsey"
    },
    {
        "code": "GY",
        "label": "Gujana"
    },
    {
        "code": "GF",
        "label": "Gujana Francuska"
    },
    {
        "code": "GP",
        "label": "Gwadelupa"
    },
    {
        "code": "GT",
        "label": "Gwatemala"
    },
    {
        "code": "GN",
        "label": "Gwinea"
    },
    {
        "code": "GW",
        "label": "Gwinea Bissau"
    },
    {
        "code": "GQ",
        "label": "Gwinea Równikowa"
    },
    {
        "code": "HT",
        "label": "Haiti"
    },
    {
        "code": "ES",
        "label": "Hiszpania"
    },
    {
        "code": "NL",
        "label": "Holandia"
    },
    {
        "code": "HN",
        "label": "Honduras"
    },
    {
        "code": "IN",
        "label": "Indie"
    },
    {
        "code": "ID",
        "label": "Indonezja"
    },
    {
        "code": "IQ",
        "label": "Irak"
    },
    {
        "code": "IR",
        "label": "Iran"
    },
    {
        "code": "IE",
        "label": "Irlandia"
    },
    {
        "code": "IS",
        "label": "Islandia"
    },
    {
        "code": "IL",
        "label": "Izrael"
    },
    {
        "code": "JM",
        "label": "Jamajka"
    },
    {
        "code": "JP",
        "label": "Japonia"
    },
    {
        "code": "YE",
        "label": "Jemen"
    },
    {
        "code": "JE",
        "label": "Jersey"
    },
    {
        "code": "JO",
        "label": "Jordania"
    },
    {
        "code": "KY",
        "label": "Kajmany"
    },
    {
        "code": "KH",
        "label": "Kambodża"
    },
    {
        "code": "CM",
        "label": "Kamerun"
    },
    {
        "code": "CA",
        "label": "Kanada"
    },
    {
        "code": "QA",
        "label": "Katar"
    },
    {
        "code": "KZ",
        "label": "Kazachstan"
    },
    {
        "code": "KE",
        "label": "Kenia"
    },
    {
        "code": "KG",
        "label": "Kirgistan"
    },
    {
        "code": "KI",
        "label": "Kiribati"
    },
    {
        "code": "CO",
        "label": "Kolumbia"
    },
    {
        "code": "KM",
        "label": "Komory"
    },
    {
        "code": "CG",
        "label": "Kongo"
    },
    {
        "code": "KR",
        "label": "Korea Południowa"
    },
    {
        "code": "KP",
        "label": "Korea Północna"
    },
    {
        "code": "CR",
        "label": "Kostaryka"
    },
    {
        "code": "CU",
        "label": "Kuba"
    },
    {
        "code": "KW",
        "label": "Kuwejt"
    },
    {
        "code": "LA",
        "label": "Laos"
    },
    {
        "code": "LS",
        "label": "Lesotho"
    },
    {
        "code": "LB",
        "label": "Liban"
    },
    {
        "code": "LR",
        "label": "Liberia"
    },
    {
        "code": "LY",
        "label": "Libia"
    },
    {
        "code": "LI",
        "label": "Liechtenstein"
    },
    {
        "code": "LT",
        "label": "Litwa"
    },
    {
        "code": "LU",
        "label": "Luksemburg"
    },
    {
        "code": "LV",
        "label": "Łotwa"
    },
    {
        "code": "MK",
        "label": "Macedonia Północna"
    },
    {
        "code": "MG",
        "label": "Madagaskar"
    },
    {
        "code": "YT",
        "label": "Majotta"
    },
    {
        "code": "MW",
        "label": "Malawi"
    },
    {
        "code": "MV",
        "label": "Malediwy"
    },
    {
        "code": "MY",
        "label": "Malezja"
    },
    {
        "code": "ML",
        "label": "Mali"
    },
    {
        "code": "MT",
        "label": "Malta"
    },
    {
        "code": "MP",
        "label": "Mariany Północne"
    },
    {
        "code": "MA",
        "label": "Maroko"
    },
    {
        "code": "MQ",
        "label": "Martynika"
    },
    {
        "code": "MR",
        "label": "Mauretania"
    },
    {
        "code": "MU",
        "label": "Mauritius"
    },
    {
        "code": "MX",
        "label": "Meksyk"
    },
    {
        "code": "FM",
        "label": "Mikronezja"
    },
    {
        "code": "MM",
        "label": "Mjanma (Birma)"
    },
    {
        "code": "MD",
        "label": "Mołdawia"
    },
    {
        "code": "MC",
        "label": "Monako"
    },
    {
        "code": "MN",
        "label": "Mongolia"
    },
    {
        "code": "MS",
        "label": "Montserrat"
    },
    {
        "code": "MZ",
        "label": "Mozambik"
    },
    {
        "code": "NA",
        "label": "Namibia"
    },
    {
        "code": "NR",
        "label": "Nauru"
    },
    {
        "code": "NP",
        "label": "Nepal"
    },
    {
        "code": "BQ",
        "label": "Niderlandy Karaibskie"
    },
    {
        "code": "DE",
        "label": "Niemcy"
    },
    {
        "code": "NE",
        "label": "Niger"
    },
    {
        "code": "NG",
        "label": "Nigeria"
    },
    {
        "code": "NI",
        "label": "Nikaragua"
    },
    {
        "code": "NU",
        "label": "Niue"
    },
    {
        "code": "NF",
        "label": "Norfolk"
    },
    {
        "code": "NO",
        "label": "Norwegia"
    },
    {
        "code": "NC",
        "label": "Nowa Kaledonia"
    },
    {
        "code": "NZ",
        "label": "Nowa Zelandia"
    },
    {
        "code": "OM",
        "label": "Oman"
    },
    {
        "code": "PK",
        "label": "Pakistan"
    },
    {
        "code": "PW",
        "label": "Palau"
    },
    {
        "code": "PA",
        "label": "Panama"
    },
    {
        "code": "PG",
        "label": "Papua-Nowa Gwinea"
    },
    {
        "code": "PY",
        "label": "Paragwaj"
    },
    {
        "code": "PE",
        "label": "Peru"
    },
    {
        "code": "PN",
        "label": "Pitcairn"
    },
    {
        "code": "PF",
        "label": "Polinezja Francuska"
    },
    {
        "code": "PL",
        "label": "Polska"
    },
    {
        "code": "PR",
        "label": "Portoryko"
    },
    {
        "code": "PT",
        "label": "Portugalia"
    },
    {
        "code": "ZA",
        "label": "Republika Południowej Afryki"
    },
    {
        "code": "CF",
        "label": "Republika Środkowoafrykańska"
    },
    {
        "code": "CV",
        "label": "Republika Zielonego Przylądka"
    },
    {
        "code": "RE",
        "label": "Reunion"
    },
    // {
    //     "code": "RU",
    //     "label": "Rosja"
    // },
    {
        "code": "RO",
        "label": "Rumunia"
    },
    {
        "code": "RW",
        "label": "Rwanda"
    },
    {
        "code": "EH",
        "label": "Sahara Zachodnia"
    },
    {
        "code": "KN",
        "label": "Saint Kitts i Nevis"
    },
    {
        "code": "LC",
        "label": "Saint Lucia"
    },
    {
        "code": "VC",
        "label": "Saint Vincent i Grenadyny"
    },
    {
        "code": "BL",
        "label": "Saint-Barthélemy"
    },
    {
        "code": "MF",
        "label": "Saint-Martin"
    },
    {
        "code": "PM",
        "label": "Saint-Pierre i Miquelon"
    },
    {
        "code": "SV",
        "label": "Salwador"
    },
    {
        "code": "WS",
        "label": "Samoa"
    },
    {
        "code": "AS",
        "label": "Samoa Amerykańskie"
    },
    {
        "code": "SM",
        "label": "San Marino"
    },
    {
        "code": "SN",
        "label": "Senegal"
    },
    {
        "code": "RS",
        "label": "Serbia"
    },
    {
        "code": "SC",
        "label": "Seszele"
    },
    {
        "code": "SL",
        "label": "Sierra Leone"
    },
    {
        "code": "SG",
        "label": "Singapur"
    },
    {
        "code": "SX",
        "label": "Sint Maarten"
    },
    {
        "code": "SK",
        "label": "Słowacja"
    },
    {
        "code": "SI",
        "label": "Słowenia"
    },
    {
        "code": "SO",
        "label": "Somalia"
    },
    {
        "code": "HK",
        "label": "SRA Hongkong (Chiny)"
    },
    {
        "code": "MO",
        "label": "SRA Makau (Chiny)"
    },
    {
        "code": "LK",
        "label": "Sri Lanka"
    },
    {
        "code": "US",
        "label": "Stany Zjednoczone"
    },
    {
        "code": "SD",
        "label": "Sudan"
    },
    {
        "code": "SS",
        "label": "Sudan Południowy"
    },
    {
        "code": "SR",
        "label": "Surinam"
    },
    {
        "code": "SJ",
        "label": "Svalbard i Jan Mayen"
    },
    {
        "code": "SY",
        "label": "Syria"
    },
    {
        "code": "CH",
        "label": "Szwajcaria"
    },
    {
        "code": "SE",
        "label": "Szwecja"
    },
    {
        "code": "TJ",
        "label": "Tadżykistan"
    },
    {
        "code": "TH",
        "label": "Tajlandia"
    },
    {
        "code": "TW",
        "label": "Tajwan"
    },
    {
        "code": "TZ",
        "label": "Tanzania"
    },
    {
        "code": "PS",
        "label": "Terytoria Palestyńskie"
    },
    {
        "code": "TL",
        "label": "Timor Wschodni"
    },
    {
        "code": "TG",
        "label": "Togo"
    },
    {
        "code": "TK",
        "label": "Tokelau"
    },
    {
        "code": "TO",
        "label": "Tonga"
    },
    {
        "code": "TT",
        "label": "Trynidad i Tobago"
    },
    {
        "code": "TN",
        "label": "Tunezja"
    },
    {
        "code": "TR",
        "label": "Turcja"
    },
    {
        "code": "TM",
        "label": "Turkmenistan"
    },
    {
        "code": "TC",
        "label": "Turks i Caicos"
    },
    {
        "code": "TV",
        "label": "Tuvalu"
    },
    {
        "code": "UG",
        "label": "Uganda"
    },
    {
        "code": "UA",
        "label": "Ukraina"
    },
    {
        "code": "UY",
        "label": "Urugwaj"
    },
    {
        "code": "UZ",
        "label": "Uzbekistan"
    },
    {
        "code": "VU",
        "label": "Vanuatu"
    },
    {
        "code": "WF",
        "label": "Wallis i Futuna"
    },
    {
        "code": "VA",
        "label": "Watykan"
    },
    {
        "code": "VE",
        "label": "Wenezuela"
    },
    {
        "code": "HU",
        "label": "Węgry"
    },
    {
        "code": "GB",
        "label": "Wielka Brytania"
    },
    {
        "code": "VN",
        "label": "Wietnam"
    },
    {
        "code": "IT",
        "label": "Włochy"
    },
    {
        "code": "BV",
        "label": "Wyspa Bouveta"
    },
    {
        "code": "CX",
        "label": "Wyspa Bożego Narodzenia"
    },
    {
        "code": "IM",
        "label": "Wyspa Man"
    },
    {
        "code": "SH",
        "label": "Wyspa Świętej Heleny"
    },
    {
        "code": "AX",
        "label": "Wyspy Alandzkie"
    },
    {
        "code": "CK",
        "label": "Wyspy Cooka"
    },
    {
        "code": "VI",
        "label": "Wyspy Dziewicze Stanów Zjednoczonych"
    },
    {
        "code": "HM",
        "label": "Wyspy Heard i McDonalda"
    },
    {
        "code": "CC",
        "label": "Wyspy Kokosowe"
    },
    {
        "code": "MH",
        "label": "Wyspy Marshalla"
    },
    {
        "code": "FO",
        "label": "Wyspy Owcze"
    },
    {
        "code": "SB",
        "label": "Wyspy Salomona"
    },
    {
        "code": "ST",
        "label": "Wyspy Świętego Tomasza i Książęca"
    },
    {
        "code": "ZM",
        "label": "Zambia"
    },
    {
        "code": "ZW",
        "label": "Zimbabwe"
    },
    {
        "code": "AE",
        "label": "Zjednoczone Emiraty Arabskie"
    }
];
export const countries_es = [
    {
        "code": "AF",
        "label": "Afganistán"
    },
    {
        "code": "AL",
        "label": "Albania"
    },
    {
        "code": "DE",
        "label": "Alemania"
    },
    {
        "code": "AD",
        "label": "Andorra"
    },
    {
        "code": "AO",
        "label": "Angola"
    },
    {
        "code": "AI",
        "label": "Anguila"
    },
    {
        "code": "AQ",
        "label": "Antártida"
    },
    {
        "code": "AG",
        "label": "Antigua y Barbuda"
    },
    {
        "code": "SA",
        "label": "Arabia Saudí"
    },
    {
        "code": "DZ",
        "label": "Argelia"
    },
    {
        "code": "AR",
        "label": "Argentina"
    },
    {
        "code": "AM",
        "label": "Armenia"
    },
    {
        "code": "AW",
        "label": "Aruba"
    },
    {
        "code": "AU",
        "label": "Australia"
    },
    {
        "code": "AT",
        "label": "Austria"
    },
    {
        "code": "AZ",
        "label": "Azerbaiyán"
    },
    {
        "code": "BS",
        "label": "Bahamas"
    },
    {
        "code": "BD",
        "label": "Bangladés"
    },
    {
        "code": "BB",
        "label": "Barbados"
    },
    {
        "code": "BH",
        "label": "Baréin"
    },
    {
        "code": "BE",
        "label": "Bélgica"
    },
    {
        "code": "BZ",
        "label": "Belice"
    },
    {
        "code": "BJ",
        "label": "Benín"
    },
    {
        "code": "BM",
        "label": "Bermudas"
    },
    {
        "code": "BY",
        "label": "Bielorrusia"
    },
    {
        "code": "BO",
        "label": "Bolivia"
    },
    {
        "code": "BA",
        "label": "Bosnia y Herzegovina"
    },
    {
        "code": "BW",
        "label": "Botsuana"
    },
    {
        "code": "BR",
        "label": "Brasil"
    },
    {
        "code": "BN",
        "label": "Brunéi"
    },
    {
        "code": "BG",
        "label": "Bulgaria"
    },
    {
        "code": "BF",
        "label": "Burkina Faso"
    },
    {
        "code": "BI",
        "label": "Burundi"
    },
    {
        "code": "BT",
        "label": "Bután"
    },
    {
        "code": "CV",
        "label": "Cabo Verde"
    },
    {
        "code": "KH",
        "label": "Camboya"
    },
    {
        "code": "CM",
        "label": "Camerún"
    },
    {
        "code": "CA",
        "label": "Canadá"
    },
    {
        "code": "BQ",
        "label": "Caribe neerlandés"
    },
    {
        "code": "QA",
        "label": "Catar"
    },
    {
        "code": "TD",
        "label": "Chad"
    },
    {
        "code": "CZ",
        "label": "Chequia"
    },
    {
        "code": "CL",
        "label": "Chile"
    },
    {
        "code": "CN",
        "label": "China"
    },
    {
        "code": "CY",
        "label": "Chipre"
    },
    {
        "code": "VA",
        "label": "Ciudad del Vaticano"
    },
    {
        "code": "CO",
        "label": "Colombia"
    },
    {
        "code": "KM",
        "label": "Comoras"
    },
    {
        "code": "CG",
        "label": "Congo"
    },
    {
        "code": "KP",
        "label": "Corea del Norte"
    },
    {
        "code": "KR",
        "label": "Corea del Sur"
    },
    {
        "code": "CR",
        "label": "Costa Rica"
    },
    {
        "code": "CI",
        "label": "Côte d’Ivoire"
    },
    {
        "code": "HR",
        "label": "Croacia"
    },
    {
        "code": "CU",
        "label": "Cuba"
    },
    {
        "code": "CW",
        "label": "Curazao"
    },
    {
        "code": "DK",
        "label": "Dinamarca"
    },
    {
        "code": "DM",
        "label": "Dominica"
    },
    {
        "code": "EC",
        "label": "Ecuador"
    },
    {
        "code": "EG",
        "label": "Egipto"
    },
    {
        "code": "SV",
        "label": "El Salvador"
    },
    {
        "code": "AE",
        "label": "Emiratos Árabes Unidos"
    },
    {
        "code": "ER",
        "label": "Eritrea"
    },
    {
        "code": "SK",
        "label": "Eslovaquia"
    },
    {
        "code": "SI",
        "label": "Eslovenia"
    },
    {
        "code": "ES",
        "label": "España"
    },
    {
        "code": "US",
        "label": "Estados Unidos"
    },
    {
        "code": "EE",
        "label": "Estonia"
    },
    {
        "code": "SZ",
        "label": "Esuatini"
    },
    {
        "code": "ET",
        "label": "Etiopía"
    },
    {
        "code": "PH",
        "label": "Filipinas"
    },
    {
        "code": "FI",
        "label": "Finlandia"
    },
    {
        "code": "FJ",
        "label": "Fiyi"
    },
    {
        "code": "FR",
        "label": "Francia"
    },
    {
        "code": "GA",
        "label": "Gabón"
    },
    {
        "code": "GM",
        "label": "Gambia"
    },
    {
        "code": "GE",
        "label": "Georgia"
    },
    {
        "code": "GH",
        "label": "Ghana"
    },
    {
        "code": "GI",
        "label": "Gibraltar"
    },
    {
        "code": "GD",
        "label": "Granada"
    },
    {
        "code": "GR",
        "label": "Grecia"
    },
    {
        "code": "GL",
        "label": "Groenlandia"
    },
    {
        "code": "GP",
        "label": "Guadalupe"
    },
    {
        "code": "GU",
        "label": "Guam"
    },
    {
        "code": "GT",
        "label": "Guatemala"
    },
    {
        "code": "GF",
        "label": "Guayana Francesa"
    },
    {
        "code": "GG",
        "label": "Guernsey"
    },
    {
        "code": "GN",
        "label": "Guinea"
    },
    {
        "code": "GQ",
        "label": "Guinea Ecuatorial"
    },
    {
        "code": "GW",
        "label": "Guinea-Bisáu"
    },
    {
        "code": "GY",
        "label": "Guyana"
    },
    {
        "code": "HT",
        "label": "Haití"
    },
    {
        "code": "HN",
        "label": "Honduras"
    },
    {
        "code": "HU",
        "label": "Hungría"
    },
    {
        "code": "IN",
        "label": "India"
    },
    {
        "code": "ID",
        "label": "Indonesia"
    },
    {
        "code": "IQ",
        "label": "Irak"
    },
    {
        "code": "IR",
        "label": "Irán"
    },
    {
        "code": "IE",
        "label": "Irlanda"
    },
    {
        "code": "BV",
        "label": "Isla Bouvet"
    },
    {
        "code": "IM",
        "label": "Isla de Man"
    },
    {
        "code": "CX",
        "label": "Isla de Navidad"
    },
    {
        "code": "NF",
        "label": "Isla Norfolk"
    },
    {
        "code": "IS",
        "label": "Islandia"
    },
    {
        "code": "AX",
        "label": "Islas Åland"
    },
    {
        "code": "KY",
        "label": "Islas Caimán"
    },
    {
        "code": "CC",
        "label": "Islas Cocos"
    },
    {
        "code": "CK",
        "label": "Islas Cook"
    },
    {
        "code": "FO",
        "label": "Islas Feroe"
    },
    {
        "code": "GS",
        "label": "Islas Georgia del Sur y Sandwich del Sur"
    },
    {
        "code": "HM",
        "label": "Islas Heard y McDonald"
    },
    {
        "code": "FK",
        "label": "Islas Malvinas"
    },
    {
        "code": "MP",
        "label": "Islas Marianas del Norte"
    },
    {
        "code": "MH",
        "label": "Islas Marshall"
    },
    {
        "code": "UM",
        "label": "Islas menores alejadas de EE. UU."
    },
    {
        "code": "PN",
        "label": "Islas Pitcairn"
    },
    {
        "code": "SB",
        "label": "Islas Salomón"
    },
    {
        "code": "TC",
        "label": "Islas Turcas y Caicos"
    },
    {
        "code": "VG",
        "label": "Islas Vírgenes Británicas"
    },
    {
        "code": "VI",
        "label": "Islas Vírgenes de EE. UU."
    },
    {
        "code": "IL",
        "label": "Israel"
    },
    {
        "code": "IT",
        "label": "Italia"
    },
    {
        "code": "JM",
        "label": "Jamaica"
    },
    {
        "code": "JP",
        "label": "Japón"
    },
    {
        "code": "JE",
        "label": "Jersey"
    },
    {
        "code": "JO",
        "label": "Jordania"
    },
    {
        "code": "KZ",
        "label": "Kazajistán"
    },
    {
        "code": "KE",
        "label": "Kenia"
    },
    {
        "code": "KG",
        "label": "Kirguistán"
    },
    {
        "code": "KI",
        "label": "Kiribati"
    },
    {
        "code": "KW",
        "label": "Kuwait"
    },
    {
        "code": "LA",
        "label": "Laos"
    },
    {
        "code": "LS",
        "label": "Lesoto"
    },
    {
        "code": "LV",
        "label": "Letonia"
    },
    {
        "code": "LB",
        "label": "Líbano"
    },
    {
        "code": "LR",
        "label": "Liberia"
    },
    {
        "code": "LY",
        "label": "Libia"
    },
    {
        "code": "LI",
        "label": "Liechtenstein"
    },
    {
        "code": "LT",
        "label": "Lituania"
    },
    {
        "code": "LU",
        "label": "Luxemburgo"
    },
    {
        "code": "MK",
        "label": "Macedonia del Norte"
    },
    {
        "code": "MG",
        "label": "Madagascar"
    },
    {
        "code": "MY",
        "label": "Malasia"
    },
    {
        "code": "MW",
        "label": "Malaui"
    },
    {
        "code": "MV",
        "label": "Maldivas"
    },
    {
        "code": "ML",
        "label": "Mali"
    },
    {
        "code": "MT",
        "label": "Malta"
    },
    {
        "code": "MA",
        "label": "Marruecos"
    },
    {
        "code": "MQ",
        "label": "Martinica"
    },
    {
        "code": "MU",
        "label": "Mauricio"
    },
    {
        "code": "MR",
        "label": "Mauritania"
    },
    {
        "code": "YT",
        "label": "Mayotte"
    },
    {
        "code": "MX",
        "label": "México"
    },
    {
        "code": "FM",
        "label": "Micronesia"
    },
    {
        "code": "MD",
        "label": "Moldavia"
    },
    {
        "code": "MC",
        "label": "Mónaco"
    },
    {
        "code": "MN",
        "label": "Mongolia"
    },
    {
        "code": "ME",
        "label": "Montenegro"
    },
    {
        "code": "MS",
        "label": "Montserrat"
    },
    {
        "code": "MZ",
        "label": "Mozambique"
    },
    {
        "code": "MM",
        "label": "Myanmar (Birmania)"
    },
    {
        "code": "NA",
        "label": "Namibia"
    },
    {
        "code": "NR",
        "label": "Nauru"
    },
    {
        "code": "NP",
        "label": "Nepal"
    },
    {
        "code": "NI",
        "label": "Nicaragua"
    },
    {
        "code": "NE",
        "label": "Níger"
    },
    {
        "code": "NG",
        "label": "Nigeria"
    },
    {
        "code": "NU",
        "label": "Niue"
    },
    {
        "code": "NO",
        "label": "Noruega"
    },
    {
        "code": "NC",
        "label": "Nueva Caledonia"
    },
    {
        "code": "NZ",
        "label": "Nueva Zelanda"
    },
    {
        "code": "OM",
        "label": "Omán"
    },
    {
        "code": "NL",
        "label": "Países Bajos"
    },
    {
        "code": "PK",
        "label": "Pakistán"
    },
    {
        "code": "PW",
        "label": "Palaos"
    },
    {
        "code": "PA",
        "label": "Panamá"
    },
    {
        "code": "PG",
        "label": "Papúa Nueva Guinea"
    },
    {
        "code": "PY",
        "label": "Paraguay"
    },
    {
        "code": "PE",
        "label": "Perú"
    },
    {
        "code": "PF",
        "label": "Polinesia Francesa"
    },
    {
        "code": "PL",
        "label": "Polonia"
    },
    {
        "code": "PT",
        "label": "Portugal"
    },
    {
        "code": "PR",
        "label": "Puerto Rico"
    },
    {
        "code": "HK",
        "label": "RAE de Hong Kong (China)"
    },
    {
        "code": "MO",
        "label": "RAE de Macao (China)"
    },
    {
        "code": "GB",
        "label": "Reino Unido"
    },
    {
        "code": "CF",
        "label": "República Centroafricana"
    },
    {
        "code": "CD",
        "label": "República Democrática del Congo"
    },
    {
        "code": "DO",
        "label": "República Dominicana"
    },
    {
        "code": "RE",
        "label": "Reunión"
    },
    {
        "code": "RW",
        "label": "Ruanda"
    },
    {
        "code": "RO",
        "label": "Rumanía"
    },
    // {
    //     "code": "RU",
    //     "label": "Rusia"
    // },
    {
        "code": "EH",
        "label": "Sáhara Occidental"
    },
    {
        "code": "WS",
        "label": "Samoa"
    },
    {
        "code": "AS",
        "label": "Samoa Americana"
    },
    {
        "code": "BL",
        "label": "San Bartolomé"
    },
    {
        "code": "KN",
        "label": "San Cristóbal y Nieves"
    },
    {
        "code": "SM",
        "label": "San Marino"
    },
    {
        "code": "MF",
        "label": "San Martín"
    },
    {
        "code": "PM",
        "label": "San Pedro y Miquelón"
    },
    {
        "code": "VC",
        "label": "San Vicente y las Granadinas"
    },
    {
        "code": "SH",
        "label": "Santa Elena"
    },
    {
        "code": "LC",
        "label": "Santa Lucía"
    },
    {
        "code": "ST",
        "label": "Santo Tomé y Príncipe"
    },
    {
        "code": "SN",
        "label": "Senegal"
    },
    {
        "code": "RS",
        "label": "Serbia"
    },
    {
        "code": "SC",
        "label": "Seychelles"
    },
    {
        "code": "SL",
        "label": "Sierra Leona"
    },
    {
        "code": "SG",
        "label": "Singapur"
    },
    {
        "code": "SX",
        "label": "Sint Maarten"
    },
    {
        "code": "SY",
        "label": "Siria"
    },
    {
        "code": "SO",
        "label": "Somalia"
    },
    {
        "code": "LK",
        "label": "Sri Lanka"
    },
    {
        "code": "ZA",
        "label": "Sudáfrica"
    },
    {
        "code": "SD",
        "label": "Sudán"
    },
    {
        "code": "SS",
        "label": "Sudán del Sur"
    },
    {
        "code": "SE",
        "label": "Suecia"
    },
    {
        "code": "CH",
        "label": "Suiza"
    },
    {
        "code": "SR",
        "label": "Surinam"
    },
    {
        "code": "SJ",
        "label": "Svalbard y Jan Mayen"
    },
    {
        "code": "TH",
        "label": "Tailandia"
    },
    {
        "code": "TW",
        "label": "Taiwán"
    },
    {
        "code": "TZ",
        "label": "Tanzania"
    },
    {
        "code": "TJ",
        "label": "Tayikistán"
    },
    {
        "code": "IO",
        "label": "Territorio Británico del Océano Índico"
    },
    {
        "code": "TF",
        "label": "Territorios Australes Franceses"
    },
    {
        "code": "PS",
        "label": "Territorios Palestinos"
    },
    {
        "code": "TL",
        "label": "Timor-Leste"
    },
    {
        "code": "TG",
        "label": "Togo"
    },
    {
        "code": "TK",
        "label": "Tokelau"
    },
    {
        "code": "TO",
        "label": "Tonga"
    },
    {
        "code": "TT",
        "label": "Trinidad y Tobago"
    },
    {
        "code": "TN",
        "label": "Túnez"
    },
    {
        "code": "TM",
        "label": "Turkmenistán"
    },
    {
        "code": "TR",
        "label": "Turquía"
    },
    {
        "code": "TV",
        "label": "Tuvalu"
    },
    {
        "code": "UA",
        "label": "Ucrania"
    },
    {
        "code": "UG",
        "label": "Uganda"
    },
    {
        "code": "UY",
        "label": "Uruguay"
    },
    {
        "code": "UZ",
        "label": "Uzbekistán"
    },
    {
        "code": "VU",
        "label": "Vanuatu"
    },
    {
        "code": "VE",
        "label": "Venezuela"
    },
    {
        "code": "VN",
        "label": "Vietnam"
    },
    {
        "code": "WF",
        "label": "Wallis y Futuna"
    },
    {
        "code": "YE",
        "label": "Yemen"
    },
    {
        "code": "DJ",
        "label": "Yibuti"
    },
    {
        "code": "ZM",
        "label": "Zambia"
    },
    {
        "code": "ZW",
        "label": "Zimbabue"
    }
];
export const countries_ru = [
    {
        "code": "AU",
        "label": "Австралия"
    },
    {
        "code": "AT",
        "label": "Австрия"
    },
    {
        "code": "AZ",
        "label": "Азербайджан"
    },
    {
        "code": "AX",
        "label": "Аландские о-ва"
    },
    {
        "code": "AL",
        "label": "Албания"
    },
    {
        "code": "DZ",
        "label": "Алжир"
    },
    {
        "code": "AS",
        "label": "Американское Самоа"
    },
    {
        "code": "AI",
        "label": "Ангилья"
    },
    {
        "code": "AO",
        "label": "Ангола"
    },
    {
        "code": "AD",
        "label": "Андорра"
    },
    {
        "code": "AQ",
        "label": "Антарктида"
    },
    {
        "code": "AG",
        "label": "Антигуа и Барбуда"
    },
    {
        "code": "AR",
        "label": "Аргентина"
    },
    {
        "code": "AM",
        "label": "Армения"
    },
    {
        "code": "AW",
        "label": "Аруба"
    },
    {
        "code": "AF",
        "label": "Афганистан"
    },
    {
        "code": "BS",
        "label": "Багамы"
    },
    {
        "code": "BD",
        "label": "Бангладеш"
    },
    {
        "code": "BB",
        "label": "Барбадос"
    },
    {
        "code": "BH",
        "label": "Бахрейн"
    },
    {
        "code": "BY",
        "label": "Беларусь"
    },
    {
        "code": "BZ",
        "label": "Белиз"
    },
    {
        "code": "BE",
        "label": "Бельгия"
    },
    {
        "code": "BJ",
        "label": "Бенин"
    },
    {
        "code": "BM",
        "label": "Бермудские о-ва"
    },
    {
        "code": "BG",
        "label": "Болгария"
    },
    {
        "code": "BO",
        "label": "Боливия"
    },
    {
        "code": "BQ",
        "label": "Бонэйр, Синт-Эстатиус и Саба"
    },
    {
        "code": "BA",
        "label": "Босния и Герцеговина"
    },
    {
        "code": "BW",
        "label": "Ботсвана"
    },
    {
        "code": "BR",
        "label": "Бразилия"
    },
    {
        "code": "IO",
        "label": "Британская территория в Индийском океане"
    },
    {
        "code": "BN",
        "label": "Бруней-Даруссалам"
    },
    {
        "code": "BF",
        "label": "Буркина-Фасо"
    },
    {
        "code": "BI",
        "label": "Бурунди"
    },
    {
        "code": "BT",
        "label": "Бутан"
    },
    {
        "code": "VU",
        "label": "Вануату"
    },
    {
        "code": "VA",
        "label": "Ватикан"
    },
    {
        "code": "GB",
        "label": "Великобритания"
    },
    {
        "code": "HU",
        "label": "Венгрия"
    },
    {
        "code": "VE",
        "label": "Венесуэла"
    },
    {
        "code": "VG",
        "label": "Виргинские о-ва (Великобритания)"
    },
    {
        "code": "VI",
        "label": "Виргинские о-ва (США)"
    },
    {
        "code": "UM",
        "label": "Внешние малые о-ва (США)"
    },
    {
        "code": "TL",
        "label": "Восточный Тимор"
    },
    {
        "code": "VN",
        "label": "Вьетнам"
    },
    {
        "code": "GA",
        "label": "Габон"
    },
    {
        "code": "HT",
        "label": "Гаити"
    },
    {
        "code": "GY",
        "label": "Гайана"
    },
    {
        "code": "GM",
        "label": "Гамбия"
    },
    {
        "code": "GH",
        "label": "Гана"
    },
    {
        "code": "GP",
        "label": "Гваделупа"
    },
    {
        "code": "GT",
        "label": "Гватемала"
    },
    {
        "code": "GN",
        "label": "Гвинея"
    },
    {
        "code": "GW",
        "label": "Гвинея-Бисау"
    },
    {
        "code": "DE",
        "label": "Германия"
    },
    {
        "code": "GG",
        "label": "Гернси"
    },
    {
        "code": "GI",
        "label": "Гибралтар"
    },
    {
        "code": "HN",
        "label": "Гондурас"
    },
    {
        "code": "HK",
        "label": "Гонконг (САР)"
    },
    {
        "code": "GD",
        "label": "Гренада"
    },
    {
        "code": "GL",
        "label": "Гренландия"
    },
    {
        "code": "GR",
        "label": "Греция"
    },
    {
        "code": "GE",
        "label": "Грузия"
    },
    {
        "code": "GU",
        "label": "Гуам"
    },
    {
        "code": "DK",
        "label": "Дания"
    },
    {
        "code": "JE",
        "label": "Джерси"
    },
    {
        "code": "DJ",
        "label": "Джибути"
    },
    {
        "code": "DM",
        "label": "Доминика"
    },
    {
        "code": "DO",
        "label": "Доминиканская Республика"
    },
    {
        "code": "EG",
        "label": "Египет"
    },
    {
        "code": "ZM",
        "label": "Замбия"
    },
    {
        "code": "EH",
        "label": "Западная Сахара"
    },
    {
        "code": "ZW",
        "label": "Зимбабве"
    },
    {
        "code": "IL",
        "label": "Израиль"
    },
    {
        "code": "IN",
        "label": "Индия"
    },
    {
        "code": "ID",
        "label": "Индонезия"
    },
    {
        "code": "JO",
        "label": "Иордания"
    },
    {
        "code": "IQ",
        "label": "Ирак"
    },
    {
        "code": "IR",
        "label": "Иран"
    },
    {
        "code": "IE",
        "label": "Ирландия"
    },
    {
        "code": "IS",
        "label": "Исландия"
    },
    {
        "code": "ES",
        "label": "Испания"
    },
    {
        "code": "IT",
        "label": "Италия"
    },
    {
        "code": "YE",
        "label": "Йемен"
    },
    {
        "code": "CV",
        "label": "Кабо-Верде"
    },
    {
        "code": "KZ",
        "label": "Казахстан"
    },
    {
        "code": "KH",
        "label": "Камбоджа"
    },
    {
        "code": "CM",
        "label": "Камерун"
    },
    {
        "code": "CA",
        "label": "Канада"
    },
    {
        "code": "QA",
        "label": "Катар"
    },
    {
        "code": "KE",
        "label": "Кения"
    },
    {
        "code": "CY",
        "label": "Кипр"
    },
    {
        "code": "KG",
        "label": "Киргизия"
    },
    {
        "code": "KI",
        "label": "Кирибати"
    },
    {
        "code": "CN",
        "label": "Китай"
    },
    {
        "code": "KP",
        "label": "КНДР"
    },
    {
        "code": "CC",
        "label": "Кокосовые о-ва"
    },
    {
        "code": "CO",
        "label": "Колумбия"
    },
    {
        "code": "KM",
        "label": "Коморы"
    },
    {
        "code": "CG",
        "label": "Конго - Браззавиль"
    },
    {
        "code": "CD",
        "label": "Конго - Киншаса"
    },
    {
        "code": "CR",
        "label": "Коста-Рика"
    },
    {
        "code": "CI",
        "label": "Кот-д’Ивуар"
    },
    {
        "code": "CU",
        "label": "Куба"
    },
    {
        "code": "KW",
        "label": "Кувейт"
    },
    {
        "code": "CW",
        "label": "Кюрасао"
    },
    {
        "code": "LA",
        "label": "Лаос"
    },
    {
        "code": "LV",
        "label": "Латвия"
    },
    {
        "code": "LS",
        "label": "Лесото"
    },
    {
        "code": "LR",
        "label": "Либерия"
    },
    {
        "code": "LB",
        "label": "Ливан"
    },
    {
        "code": "LY",
        "label": "Ливия"
    },
    {
        "code": "LT",
        "label": "Литва"
    },
    {
        "code": "LI",
        "label": "Лихтенштейн"
    },
    {
        "code": "LU",
        "label": "Люксембург"
    },
    {
        "code": "MU",
        "label": "Маврикий"
    },
    {
        "code": "MR",
        "label": "Мавритания"
    },
    {
        "code": "MG",
        "label": "Мадагаскар"
    },
    {
        "code": "YT",
        "label": "Майотта"
    },
    {
        "code": "MO",
        "label": "Макао (САР)"
    },
    {
        "code": "MW",
        "label": "Малави"
    },
    {
        "code": "MY",
        "label": "Малайзия"
    },
    {
        "code": "ML",
        "label": "Мали"
    },
    {
        "code": "MV",
        "label": "Мальдивы"
    },
    {
        "code": "MT",
        "label": "Мальта"
    },
    {
        "code": "MA",
        "label": "Марокко"
    },
    {
        "code": "MQ",
        "label": "Мартиника"
    },
    {
        "code": "MH",
        "label": "Маршалловы Острова"
    },
    {
        "code": "MX",
        "label": "Мексика"
    },
    {
        "code": "MZ",
        "label": "Мозамбик"
    },
    {
        "code": "MD",
        "label": "Молдова"
    },
    {
        "code": "MC",
        "label": "Монако"
    },
    {
        "code": "MN",
        "label": "Монголия"
    },
    {
        "code": "MS",
        "label": "Монтсеррат"
    },
    {
        "code": "MM",
        "label": "Мьянма (Бирма)"
    },
    {
        "code": "NA",
        "label": "Намибия"
    },
    {
        "code": "NR",
        "label": "Науру"
    },
    {
        "code": "NP",
        "label": "Непал"
    },
    {
        "code": "NE",
        "label": "Нигер"
    },
    {
        "code": "NG",
        "label": "Нигерия"
    },
    {
        "code": "NL",
        "label": "Нидерланды"
    },
    {
        "code": "NI",
        "label": "Никарагуа"
    },
    {
        "code": "NU",
        "label": "Ниуэ"
    },
    {
        "code": "NZ",
        "label": "Новая Зеландия"
    },
    {
        "code": "NC",
        "label": "Новая Каледония"
    },
    {
        "code": "NO",
        "label": "Норвегия"
    },
    {
        "code": "BV",
        "label": "о-в Буве"
    },
    {
        "code": "IM",
        "label": "о-в Мэн"
    },
    {
        "code": "NF",
        "label": "о-в Норфолк"
    },
    {
        "code": "CX",
        "label": "о-в Рождества"
    },
    {
        "code": "SH",
        "label": "о-в Св. Елены"
    },
    {
        "code": "PN",
        "label": "о-ва Питкэрн"
    },
    {
        "code": "TC",
        "label": "о-ва Тёркс и Кайкос"
    },
    {
        "code": "HM",
        "label": "о-ва Херд и Макдональд"
    },
    {
        "code": "AE",
        "label": "ОАЭ"
    },
    {
        "code": "OM",
        "label": "Оман"
    },
    {
        "code": "KY",
        "label": "Острова Кайман"
    },
    {
        "code": "CK",
        "label": "Острова Кука"
    },
    {
        "code": "PK",
        "label": "Пакистан"
    },
    {
        "code": "PW",
        "label": "Палау"
    },
    {
        "code": "PS",
        "label": "Палестинские территории"
    },
    {
        "code": "PA",
        "label": "Панама"
    },
    {
        "code": "PG",
        "label": "Папуа — Новая Гвинея"
    },
    {
        "code": "PY",
        "label": "Парагвай"
    },
    {
        "code": "PE",
        "label": "Перу"
    },
    {
        "code": "PL",
        "label": "Польша"
    },
    {
        "code": "PT",
        "label": "Португалия"
    },
    {
        "code": "PR",
        "label": "Пуэрто-Рико"
    },
    {
        "code": "KR",
        "label": "Республика Корея"
    },
    {
        "code": "RE",
        "label": "Реюньон"
    },
    // {
    //     "code": "RU",
    //     "label": "Россия"
    // },
    {
        "code": "RW",
        "label": "Руанда"
    },
    {
        "code": "RO",
        "label": "Румыния"
    },
    {
        "code": "SV",
        "label": "Сальвадор"
    },
    {
        "code": "WS",
        "label": "Самоа"
    },
    {
        "code": "SM",
        "label": "Сан-Марино"
    },
    {
        "code": "ST",
        "label": "Сан-Томе и Принсипи"
    },
    {
        "code": "SA",
        "label": "Саудовская Аравия"
    },
    {
        "code": "MK",
        "label": "Северная Македония"
    },
    {
        "code": "MP",
        "label": "Северные Марианские о-ва"
    },
    {
        "code": "SC",
        "label": "Сейшельские Острова"
    },
    {
        "code": "BL",
        "label": "Сен-Бартелеми"
    },
    {
        "code": "MF",
        "label": "Сен-Мартен"
    },
    {
        "code": "PM",
        "label": "Сен-Пьер и Микелон"
    },
    {
        "code": "SN",
        "label": "Сенегал"
    },
    {
        "code": "VC",
        "label": "Сент-Винсент и Гренадины"
    },
    {
        "code": "KN",
        "label": "Сент-Китс и Невис"
    },
    {
        "code": "LC",
        "label": "Сент-Люсия"
    },
    {
        "code": "RS",
        "label": "Сербия"
    },
    {
        "code": "SG",
        "label": "Сингапур"
    },
    {
        "code": "SX",
        "label": "Синт-Мартен"
    },
    {
        "code": "SY",
        "label": "Сирия"
    },
    {
        "code": "SK",
        "label": "Словакия"
    },
    {
        "code": "SI",
        "label": "Словения"
    },
    {
        "code": "US",
        "label": "Соединенные Штаты"
    },
    {
        "code": "SB",
        "label": "Соломоновы Острова"
    },
    {
        "code": "SO",
        "label": "Сомали"
    },
    {
        "code": "SD",
        "label": "Судан"
    },
    {
        "code": "SR",
        "label": "Суринам"
    },
    {
        "code": "SL",
        "label": "Сьерра-Леоне"
    },
    {
        "code": "TJ",
        "label": "Таджикистан"
    },
    {
        "code": "TH",
        "label": "Таиланд"
    },
    {
        "code": "TW",
        "label": "Тайвань"
    },
    {
        "code": "TZ",
        "label": "Танзания"
    },
    {
        "code": "TG",
        "label": "Того"
    },
    {
        "code": "TK",
        "label": "Токелау"
    },
    {
        "code": "TO",
        "label": "Тонга"
    },
    {
        "code": "TT",
        "label": "Тринидад и Тобаго"
    },
    {
        "code": "TV",
        "label": "Тувалу"
    },
    {
        "code": "TN",
        "label": "Тунис"
    },
    {
        "code": "TM",
        "label": "Туркменистан"
    },
    {
        "code": "TR",
        "label": "Турция"
    },
    {
        "code": "UG",
        "label": "Уганда"
    },
    {
        "code": "UZ",
        "label": "Узбекистан"
    },
    {
        "code": "UA",
        "label": "Украина"
    },
    {
        "code": "WF",
        "label": "Уоллис и Футуна"
    },
    {
        "code": "UY",
        "label": "Уругвай"
    },
    {
        "code": "FO",
        "label": "Фарерские о-ва"
    },
    {
        "code": "FM",
        "label": "Федеративные Штаты Микронезии"
    },
    {
        "code": "FJ",
        "label": "Фиджи"
    },
    {
        "code": "PH",
        "label": "Филиппины"
    },
    {
        "code": "FI",
        "label": "Финляндия"
    },
    {
        "code": "FK",
        "label": "Фолклендские о-ва"
    },
    {
        "code": "FR",
        "label": "Франция"
    },
    {
        "code": "GF",
        "label": "Французская Гвиана"
    },
    {
        "code": "PF",
        "label": "Французская Полинезия"
    },
    {
        "code": "TF",
        "label": "Французские Южные территории"
    },
    {
        "code": "HR",
        "label": "Хорватия"
    },
    {
        "code": "CF",
        "label": "Центрально-Африканская Республика"
    },
    {
        "code": "TD",
        "label": "Чад"
    },
    {
        "code": "ME",
        "label": "Черногория"
    },
    {
        "code": "CZ",
        "label": "Чехия"
    },
    {
        "code": "CL",
        "label": "Чили"
    },
    {
        "code": "CH",
        "label": "Швейцария"
    },
    {
        "code": "SE",
        "label": "Швеция"
    },
    {
        "code": "SJ",
        "label": "Шпицберген и Ян-Майен"
    },
    {
        "code": "LK",
        "label": "Шри-Ланка"
    },
    {
        "code": "EC",
        "label": "Эквадор"
    },
    {
        "code": "GQ",
        "label": "Экваториальная Гвинея"
    },
    {
        "code": "ER",
        "label": "Эритрея"
    },
    {
        "code": "SZ",
        "label": "Эсватини"
    },
    {
        "code": "EE",
        "label": "Эстония"
    },
    {
        "code": "ET",
        "label": "Эфиопия"
    },
    {
        "code": "GS",
        "label": "Южная Георгия и Южные Сандвичевы о-ва"
    },
    {
        "code": "ZA",
        "label": "Южно-Африканская Республика"
    },
    {
        "code": "SS",
        "label": "Южный Судан"
    },
    {
        "code": "JM",
        "label": "Ямайка"
    },
    {
        "code": "JP",
        "label": "Япония"
    }
];