import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CategoryInfo from "../ContestSchoolDetails/CategoryInfo";

export default function ({t, school, category, title}) {

    return (
        (school && category) ?
            <>
                <div>
                    <Grid container spacing={1}>
                        {title ?
                            <Grid item xs={12}>
                                <Paper elevation={0}><b>{title}</b></Paper>
                            </Grid> : null
                        }
                        <Grid item xs={3}>
                            <Paper elevation={0}>{t('schoolName')}</Paper>
                        </Grid>
                        <Grid item xs={9}>
                            <Paper elevation={0}>{school.name}</Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper elevation={0}>{t('address')}</Paper>
                        </Grid>
                        <Grid item xs={9}>
                            <Paper elevation={0}>{school.address}</Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper elevation={0}>{t('category')}</Paper>
                        </Grid>
                        <Grid item xs={9}>
                            <Paper elevation={0}>
                                <CategoryInfo country={school.country} category={category.name}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </> : null
    );
}
