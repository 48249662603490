import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CategoryInfo from "../ContestSchoolDetails/CategoryInfo";
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserService from "../../../services/UserService";
import {useSnackbar} from "notistack";
import {readError} from "../../../utils/utils";
import {error} from "../../../hoc/GlobalSnackbar/GlobalSnackbar";


export default function () {

  const {t} = useTranslation('contest-my')
  const [user, setUser] = useState({});
  const {enqueueSnackbar} = useSnackbar();


  useEffect(() => {
    UserService.getMyProfile()
      .then(response => setUser(response.data))
      .catch(reason => {
        enqueueSnackbar(readError(reason)?.statusText, error);
        setUser({fullName: 'Error reading data'});
      });
  }, []);

  return (
    user ?
    <>
      <div>
        <Grid container spacing={1}>

            <Grid item xs={12}>
              <Paper elevation={0}><b>{t('personalData')}</b></Paper>
            </Grid>
          <Grid item xs={3}>
            <Paper elevation={0}>{t('fullName')}</Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper elevation={0}>{user.fullName}</Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={0}>{t('email')}</Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper elevation={0}>{user.email}</Paper>
          </Grid>
          {
            user.accountType === 'STUDENT' ?
              <>
              <Grid item xs={3}>
                <Paper elevation={0}>{t('className')}</Paper>
              </Grid>
              <Grid item xs={9}>
                <Paper elevation={0}>{user.className}</Paper>
              </Grid>
                <Grid item xs={3}>
                  <Paper elevation={0}>{t('yearOfBirth')}</Paper>
                </Grid>
                <Grid item xs={9}>
                  <Paper elevation={0}>{user.yearOfBirth}</Paper>
                </Grid>
              </> : null
          }
        </Grid>
      </div>
    </> : <CircularProgress />
  );
}
