import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';

export function getLanguage() {
    const path = window.location.pathname;
    let language = 'en';
    if (!path.startsWith('/app')) {
        language = path.split('/')[1];
    }
    return language;
}

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: getLanguage(),
        backend: {
            /* translation file path */
            loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'en',
        debug: false,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['common', 'api-errors'],
        defaultNS: 'common',
        fallbackNS: 'common',
        nsSeparator: true,
        keySeparator: true,
        saveMissing: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true
        }
    });

export default i18n;
