import React from "react";
import {SnackbarProvider} from "notistack";
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const snackbarRef = React.createRef();

const onClickDismiss = key => () => {
    snackbarRef.current.closeSnackbar(key);
}

const transitionDown = (props) => {
    return <Slide {...props} direction="down"/>;
}

export const error = {
    variant: 'error'
}

export const success = {
    variant: 'success'
}

export default function ({children}) {

    return (
        <SnackbarProvider maxSnack={3}
                          ref={snackbarRef}
                          action={(key) => (
                              <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  onClick={onClickDismiss(key)}
                              >
                                  <CloseIcon/>
                              </IconButton>
                          )}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                          }}
                          autoHideDuration={10000}
                          TransitionComponent={transitionDown}
        >
            {children}
        </SnackbarProvider>
    );
}
