import React, {useEffect, useState} from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BaseForm from '../../components/common/BaseForm';
import {useForm} from 'react-hook-form';
import {useStyles} from '../../utils/styling';
import Grid from '@material-ui/core/Grid';
import TextFieldComponent from '../../components/common/TextFieldComponent';
import EmailFieldComponent from '../../components/common/EmailFieldComponent';
import SubmitButton from '../../components/common/SubmitButton';
import {apiService, ENDPOINTS} from '../../services/api';
import LockIcon from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import {readError} from '../../utils/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Errors from '../../components/common/Errors';
import {useTranslation} from 'react-i18next';
import {ROUTES} from '../../utils/navigation';
import PhoneFieldComponent from '../../components/common/PhoneFieldComponent';
import StatusAlert from '../../components/common/StatusAlert';
import MarketingConsent from '../SignUp/MarketingConsent';
import UserService from "../../services/UserService";

export default function () {
    const form = useForm();
    const {t} = useTranslation('my-profile');
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState();
    const [saving, setSaving] = useState(false);
    const [savingError, setSavingError] = useState();
    const [saved, setSaved] = useState(false);

    const [user, setUser] = useState({});
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        UserService.getMyProfile()
            .then(result => setUser(result.data))
            .catch(reason => setLoadingError(readError(reason)))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        form.reset(user);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const onSubmit = (data) => {
        setSaving(true);
        apiService.post(ENDPOINTS.USER.UPDATE_PROFILE(user.id), {...data, id: user.id})
            .then(result => setSaved(true))
            .catch(reason => setSavingError(readError(reason)))
            .finally(() => setSaving(false));
    };

    return (
        <BaseForm Icon={AccountCircleIcon} size='sm' title='Mój profil'>
            {loading ?
                <CircularProgress/>
                :
                <>
                    <Errors onClose={() => setLoadingError(undefined)} error={loadingError}/>
                    <form className={classes.form} onSubmit={form.handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <TextFieldComponent
                                name="fullName"
                                required
                                form={form}
                            />
                            <EmailFieldComponent
                                name="email"
                                required
                                disabled
                                form={form}
                            />
                            <PhoneFieldComponent
                                name="phoneNumber"
                                form={form}
                            />
                        </Grid>
                        <br></br>
                        <Grid container spacing={2}>
                            <MarketingConsent name='marketingConsent' form={form}/>
                        </Grid>
                        <div className={classes.wrapper}>
                            <Errors onClose={() => setSavingError(undefined)} error={savingError}/>
                            <StatusAlert show={saved} title={t('savedTitle')} onClose={() => setSaved(false)} severity='success'>
                                {t('savedMessage')}
                            </StatusAlert>
                            <SubmitButton loading={saving} classes={classes}>
                                {t('submit')}
                            </SubmitButton>
                        </div>
                    </form>
                </>
            }

            <Button
                startIcon={<LockIcon/>}
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={() => history.push(ROUTES.CHANGE_PASSWORD)}
            >
                {t('changePasswordButton')}
            </Button>
        </BaseForm>
    );
}
