import CircularProgress from '@material-ui/core/CircularProgress';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BaseForm from '../../components/common/BaseForm';
import Errors from '../../components/common/Errors';
import EventDetails from '../../components/EventDetails/EventDetails';
import { apiService, ENDPOINTS } from '../../services/api';
import EventsService from '../../services/EventsService';
import { ROUTES } from '../../utils/navigation';
import { readError } from '../../utils/utils';

export default function ({match}) {
    const form = useForm();
    const {t} = useTranslation('event-registration');
    const [loading, setLoading] = useState(true);
    const [eventId] = useState(match.params.event);
    const [event, setEvent] = useState({});
    const [error, setError] = useState();
    const history = useHistory();

    useEffect(() => {
        apiService.get(ENDPOINTS.REGISTRATION.AUTH.BY_ID(eventId))
            .then(response => {
                const data = response.data;
                setEvent(data);
                form.reset(data);

            })
            .catch(reason => setError(readError(reason)))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    const onSubmit = (data, success, failure, always) => {
        EventsService.updateEvent(event.id, data)
            .then(response => {
                history.push(ROUTES.MY_EVENTS);
                success(response);
            })
            .catch(reason => failure(reason))
            .finally(() => always());
    };

    return (
        <BaseForm title={t('titleEdit')} Icon={SchoolOutlinedIcon} size='md'>
            {
                loading ? <CircularProgress/> :
                    error ? <Errors onClose={() => setError(undefined)} error={error}/> :
                        <EventDetails onSubmit={onSubmit} submitLabel={t('submitEdit')} form={form}/>
            }
        </BaseForm>
    );
}
