import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ContestService from "../../../services/ContestService";
import {useSnackbar} from "notistack";
import {readError} from "../../../utils/utils";
import {error} from "../../../hoc/GlobalSnackbar/GlobalSnackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ResultsTable from "./ResultsTable";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ({t, category, onClose}) {

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({});
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (category && category.id) {
      console.log('Category', category)
      setOpen(true);
      setLoading(true);
      ContestService.getResultsByCategory(category.id)
        .then(response => setResults(response.data))
        .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
        .finally(() => setLoading(false));
    }
  }, [category]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {t('results')}
            </Typography>
          </Toolbar>
        </AppBar>
        {
          loading ? <CircularProgress /> : <ResultsTable t={t} results={results} />
        }
      </Dialog>
    </div>
  );
}
