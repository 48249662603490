import React from 'react';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';

export default function ({title, severity, show, children, onClose}) {

    return (
        <>
            {
                show ?
                    <Alert variant="outlined" severity={severity} onClose={onClose}>
                        <AlertTitle>{title}</AlertTitle>
                        {children}
                    </Alert>
                    :
                    null
            }
        </>
    );
}