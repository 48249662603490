import React, {useEffect, useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {useTranslation} from 'react-i18next';

export default function ({form, name, ...rest}) {

    const {t} = useTranslation('sign-up');
    const consent = t('marketingConsent');
    const [isSelected, setSelected] = useState(false);

    useEffect(() => {
        const selected = form.watch(name);
        setSelected(selected);
    }, []);

    const handleChange = (event) => {
        setSelected(event.target.checked);
    };

    return (
        !!consent ?
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isSelected}
                            onChange={handleChange}
                            name={name}
                            {...rest}
                            color="primary"
                            inputRef={form.register}
                        />
                    }
                    label={
                        consent
                    }
                />
            </> : null
    );
}