import CircularProgress from '@material-ui/core/CircularProgress';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import BaseForm from '../../components/common/BaseForm';
import EventDetails from '../../components/EventDetails/EventDetails';
import {apiService, ENDPOINTS} from '../../services/api';
import {ROUTES} from '../../utils/navigation';

export default function SchoolRegistration() {
    const form = useForm();
    const {t} = useTranslation('event-registration');
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
      const previousYearSchool = sessionStorage.getItem('PREVIOUS_YEAR_SCHOOL');
      sessionStorage.removeItem('PREVIOUS_YEAR_SCHOOL');
      const schoolData = previousYearSchool ? JSON.parse(previousYearSchool) : {};

        apiService.get(ENDPOINTS.USER.PROFILE)
            .then(response => response.data)
            .then(data => {
              if (schoolData.school) {
                form.reset({
                  'school': schoolData.school,
                  coordinators: schoolData.coordinators,
                  previousYear: true
                });
              } else {
                form.reset(
                    {
                      previousYear: false,
                      'coordinators[0].fullName': data['fullName'], 'coordinators[0].email': data['email'],
                    }
                );
              }
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (data, success, failure, always) => {
        apiService.post(ENDPOINTS.REGISTRATION.AUTH.ROOT, data)
            .then(response => {
                success(response);
                history.push(ROUTES.MY_EVENTS);
            })
            .catch(reason => failure(reason))
            .finally(() => always());
    };

    return (
        <BaseForm title={t('title')} Icon={SchoolOutlinedIcon} size='md'>
            {
                loading ? <CircularProgress/> :
                    <EventDetails onSubmit={onSubmit} submitLabel={t('submit')} form={form}/>
            }
        </BaseForm>
    );
}
