import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    countdownContainer: {
        color: '#333',
        margin: '0 auto',
        padding: '0.5rem',
        textAlign: 'center'
    },
    list: {
        paddingInlineStart: '0px'
    },
    listItem: {
        display: 'inline-block',
        fontSize: '0.5em',
        listStyleType: 'none',
        padding: '1em',
        textTransform: 'uppercase'
    },
    listSpan: {
        display: 'block',
        fontSize: '3.5rem'
    },
}));

export default function ({date, t, onTimeIsUp, currentTime, showDays = true, showHours = true, showMinutes = true}) {

    const classes = useStyles();
    const [days, setDays] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [seconds, setSeconds] = useState('');
    const [timeTo] = useState(date);

    let interval;

    const updateTimer = (date, timeDifference) => {
        if (date) {
            const now = new Date().getTime() + timeDifference;
            const remaining = date.getTime() - now;

            if (remaining >= 0) {
                const daysLeft = Math.floor((remaining) / (1000 * 60 * 60 * 24));
                const hoursLeft = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minLeft = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
                const secsLeft = Math.floor((remaining % (1000 * 60)) / 1000);

                setDays(('0' + daysLeft).slice(-2));
                setHours(('0' + hoursLeft).slice(-2));
                setMinutes(('0' + minLeft).slice(-2));
                setSeconds(('0' + secsLeft).slice(-2));
            } else {
                onTimeIsUp && onTimeIsUp();
                clearInterval(interval);
            }
        }
    }

    useEffect(() => {
        if (timeTo) {
            const now = currentTime || new Date().getTime();
            const timeDifference = now - new Date().getTime();
            updateTimer(timeTo, timeDifference);

            interval = setInterval(() => {
                updateTimer(timeTo, timeDifference);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [timeTo]);


    return (
        days ?
            <div className={classes.countdownContainer}>
                <ul className={classes.list}>
                    {showDays ? <li className={classes.listItem}><span
                        className={classes.listSpan}>{days}</span>{t('countdownDays')}</li> : null}
                    {showHours ? <li className={classes.listItem}><span
                        className={classes.listSpan}>{hours}</span>{t('countdownHours')}</li> : null}
                    {showMinutes ? <li className={classes.listItem}><span
                        className={classes.listSpan}>{minutes}</span>{t('countdownMinutes')}</li> : null}
                    <li className={classes.listItem}><span
                        className={classes.listSpan}>{seconds}</span>{t('countdownSeconds')}</li>
                </ul>
            </div> : <CircularProgress/>
    );
}
