import React, {useEffect} from 'react';
import {signOut} from '../../services/auth';
import {Redirect, useHistory} from 'react-router-dom';
import {ROUTES} from '../../utils/navigation';

export default function SignOutLink() {
    const history = useHistory();

    useEffect(() => {
        signOut();
        history.push(ROUTES.SIGN_IN);
        window.location.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Redirect to={ROUTES.SIGN_IN}/>
    );
}
