import React, {useState} from 'react';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextFieldComponent from '../../components/common/TextFieldComponent';
import EmailFieldComponent from '../../components/common/EmailFieldComponent';
import SaveIcon from '@material-ui/icons/Save';
import TableRow from '@material-ui/core/TableRow';
import {useForm} from 'react-hook-form';
import {useStyles} from '../../utils/styling';
import SubmitButton from './../../components/common/SubmitButton';

export default function ({onCancel, onSave, t}) {
    const form = useForm();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    return (
        <TableRow>
            <TableCell>
                <IconButton color="primary" component="span"
                            onClick={event => {
                                onCancel();
                                form.reset();
                            }}>
                    <DeleteIcon/>
                </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
                <TextFieldComponent
                    name="fullName"
                    required
                    form={form}
                />
            </TableCell>
            <TableCell>
                <EmailFieldComponent
                    name="email"
                    required
                    form={form}
                />
            </TableCell>
            <TableCell>
                <SubmitButton classes={classes}
                              loading={loading}
                              startIcon={<SaveIcon/>}
                              onClick={event => {
                                  form.trigger().then(value => {
                                      if (value) {
                                          setLoading(true);
                                          onSave(form.getValues(), form, () => setLoading(false));
                                      }
                                  });
                              }}>
                    {t('submitNewCoordinator')}
                </SubmitButton>
            </TableCell>
        </TableRow>
    );
}
