import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import ContestService from "../../../services/ContestService";
import {readError} from "../../../utils/utils";
import {useSnackbar} from "notistack";
import {error} from "../../../hoc/GlobalSnackbar/GlobalSnackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Alert from "../../../components/common/Alert";

export default function ({children}) {

  const {t} = useTranslation('contest-final-results');

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState({});
  const [timeToSolveFinal, setTimeToSolveFinal] = useState('');
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    ContestService.myResults()
      .then(response => setResults(response.data))
      .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (results && results.finalDate) {
      const startTime = Date.UTC(2020, 9, 17, 7, 0, 0);
      const endTime = results.finalDate;
      const dif = endTime - startTime;

      const hours = Math.floor((dif % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((dif % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((dif % (1000 * 60)) / 1000);

      setTimeToSolveFinal(`${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`);
    }
  }, [results]);

  return (
    loading ? <CircularProgress/> :
      results && <>
        <div style={{textAlign: 'center'}}>
          <Alert severity='success'>
            {t('prizesInfo')}
          </Alert>
          <br></br>
          <Typography variant="h6" component="h3" gutterBottom>
            {t('finalRank')}
          </Typography>
          <Typography variant="h2" component="h3" gutterBottom>
            <b>{results.place}</b>
          </Typography>
          {
            results.day4Points !== null ?
              <>
                <Typography variant="h6" component="h3" gutterBottom>
                  {t('pointsInFinal')}
                </Typography>
                <Typography variant="h4" component="h3" gutterBottom>
                  {results.day4Points}
                </Typography>
              </> : null
          }
          {
            timeToSolveFinal ?
              <>
                <Typography variant="h6" component="h3" gutterBottom>
                  {t('timeToSolve')}
                </Typography>
                <Typography variant="h4" component="h3" gutterBottom>
                  {timeToSolveFinal}
                </Typography>
              </> : null
          }
          <Typography variant="h6" component="h3" gutterBottom>
            {t('pointsInEliminations')}
          </Typography>
          <Typography variant="h4" component="h3" gutterBottom>
            {results.day1Points + results.day2Points + results.day3Points}
          </Typography>
        </div>
        {children}
      </>
  );
}
