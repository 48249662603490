import React from "react";
import Box from "@material-ui/core/Box";

export default function (props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
