import {apiService, ENDPOINTS} from './api';

const signInCall = (data) => {
    return apiService.post(ENDPOINTS.USER.SIGN_IN, data);
};

const getMyProfileCall = () => {
    return apiService.get(ENDPOINTS.USER.PROFILE);
};

const UserService = {
    getMyProfile: getMyProfileCall,
    signIn: signInCall
};

export default UserService;
