import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import BaseForm from '../../components/common/BaseForm';
import {ROUTES} from '../../utils/navigation';
import SignInForm from "./SignInForm";

export default function SignIn(props) {
    const history = useHistory();
    const {t} = useTranslation('sign-in');
    const [from, setFrom] = useState(props?.location?.state?.from)

    const onSignIn = () => {
        history.push(from ? from : ROUTES.ROOT);
        document.location.reload();
    };

    return (
        <BaseForm Icon={LockOutlinedIcon} title={t('title')}>
            <SignInForm onSignInCallback={onSignIn}/>
            <Grid container>
                <Grid item xs>
                    <Link component={RouterLink} to="/account-recovery" variant="body2">
                        {t('forgotPassword')}
                    </Link>
                </Grid>
                <Grid item>
                    <Link component={RouterLink} to="/sign-up" variant="body2">
                        {t('dontHaveAccount')}
                    </Link>
                </Grid>
            </Grid>
        </BaseForm>
    );
}
