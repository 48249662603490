import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isAuthenticated} from '../../services/auth';
import {ROUTES} from '../../utils/navigation';

/*
* Private route is a root that only authenticated users can access.
* If a person is not authenticated then will be redirected to '/sign-in' page.
*/
export default function PrivateRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => isAuthenticated()
                ? <Component {...props} />
                : <Redirect to={{pathname: ROUTES.SIGN_IN, state: {from: props.location}}}/>}
        />
    );
}