import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import { Trans } from 'react-i18next';
import { getLanguage } from '../../utils/i18n';

export default function ({form, t}) {

    const error = form?.errors?.['rulesConsent'];
    const rulesConsent = t('rulesConsent');
    const link = `https://kartygrabowskiego.pl/wmtday/2023/docs/wmtday2023_rules_${getLanguage()}.pdf`;

    return (
        !!rulesConsent ?
            <>
                <FormControlLabel
                    error={!!error}
                    control={
                        <Checkbox
                            name="rulesConsent"
                            color="primary"
                            required
                            inputRef={form.register({required: true})}
                        />
                    }
                    label={
                        <Trans t={t} i18nKey="rulesConsent">
                            text <a href={link} target='_blank' rel='noopener noreferrer'>text</a>
                        </Trans>
                    }
                />
                {!!error ?
                    <FormHelperText error>{error ? t('requiredValidationMessage') : undefined}</FormHelperText> : null}
            </> : null
    );
}
