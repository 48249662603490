import {getLanguage} from './i18n';
import i18next from 'i18next';

export function getBaseUrl() {
    const language = getLanguage();
    let base = '';
    if (language !== 'en') {
        base += '/' + language;
    }
    base += '/app';
    return base;
}

export function readError(reason) {
    let error = {};
    const t = i18next.getFixedT(getLanguage(), 'api-errors');
    if (reason.response) {
        const code = (reason.response.data && reason.response.data.message) || reason.response.status;
        error.status = reason.response.status;
        error.code = code.toString();
        error.statusText = t(code);
    } else {
        error.status = 500;
        error.code = '500';
        error.statusText = t('500', {details: reason.message});
    }
    return error;
}