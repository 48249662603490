import axios from 'axios';
import Cookies from 'universal-cookie/es6';
import {getLanguage} from '../utils/i18n';

const cookies = new Cookies();

const getInstance = () => {
    const authorizationBearer = cookies.get('authorization');
    if (!!authorizationBearer) {
        axios.defaults.headers['Authorization'] = authorizationBearer;
    }
    axios.defaults.headers['Accept-Language'] = getLanguage();
    return axios.create({
        baseURL: process.env.REACT_APP_API_DOMAIN,
        timeout: 120 * 1000
    });
};

const apiService = getInstance();

const setAuthorizationHeader = (authorization) => {
    apiService.defaults.headers['Authorization'] = authorization;
};

const ENDPOINTS = Object.freeze({
    USER: {
        SIGN_IN: '/login',
        SIGN_UP: '/api/users',
        PROFILE: '/api/users/profile',
        UPDATE_PROFILE: (user) => '/api/users/:user'.replace(':user', user),
        ACCOUNT_CONFIRMATION: '/api/users/confirmRegistration?token=',
        RESET_PASSWORD: '/api/users/resetPassword',
        CHANGE_RESET_PASSWORD: '/api/users/changeResetPassword',
        CHANGE_PASSWORD: '/api/users/changePassword'
    },
    REGISTRATION: {
        PUBLIC: {
            CONFIRMED_EVENTS: '/api/public/registrations',
            CONFIRMED_EVENTS_ARCHIVE: (year) => '/api/public/registrations/archive/:year'.replace(':year', year),
        },
        AUTH: {
            ROOT: '/api/registrations',
            BY_YEAR: (year) => '/api/registrations?year=:year'.replace(':year', year),
            BY_ID: (event) => '/api/registrations/:event'.replace(':event', event),
            ADD_COORDINATOR: (event) => '/api/registrations/:event/coordinators'.replace(':event', event),
            REMOVE_COORDINATOR: (event, coordinator) => '/api/registrations/:event/coordinators/:coordinator'.replace(':event', event).replace(':coordinator', coordinator),
        }
    },
    STATS: {
        REGISTRATIONS_BY_COUNTRY: '/api/public/stats/registrationsByCountry'
    },
    ADMIN: {
        REGISTRATIONS: '/api/admin/registrations',
        REGISTRATION_BY_ID: (eventId) => '/api/admin/registrations/:event'.replace(':event', eventId),
        GIVE_LICENSE: '/api/admin/registrations/give-license',
        CLEAR_GOOGLE_ID: (eventId) => '/api/admin/registrations/:event/clear-google-id'.replace(':event', eventId),
        SEND_EMAIL: (eventId) => '/api/admin/registrations/:event/send-email'.replace(':event', eventId),
        IMPERSONATE: '/api/admin/users/impersonate'
    },
    PUZZLES: {
        ALL: (language) => 'https://s3.eu-central-1.amazonaws.com/static.kartygrabowskiego.pl/wmtday/puzzles-library/:language/library.json?id=1'.replace(':language', language),
        LIKES: '/api/puzzles/likes',
        LIKE: (puzzleId) => '/api/puzzles/:puzzleId/like'.replace(':puzzleId', puzzleId),
        DISLIKE: (puzzleId) => '/api/puzzles/:puzzleId/dislike'.replace(':puzzleId', puzzleId)
    },
    CONTEST: {
        ADMIN: {
            CALCULATE_RESULTS: '/api/admin/contest/calculateResults',
        },
        MY: '/api/contest',
        MY_RESULTS: '/api/contest/results',
        FINAL_RESULTS: '/api/public/contest/results/final',
        RESULTS_BY_CATEGORY: (categoryId = ':categoryId') => '/api/contest/results/:categoryId'.replace(':categoryId', categoryId),
        START_TIME: '/api/public/contest/startDate',
        CURRENT_TIME: '/api/public/contest/time',
        REGISTER_SCHOOL: '/api/contest/registerSchool',
        REGISTER_PARTICIPANT: (accessCode = ':accessCode') => '/api/contest/registerParticipant/:accessCode'.replace(':accessCode', accessCode),
        REGISTER_NEW_ACCOUNT_PARTICIPANT: (accessCode = ':accessCode') => '/api/public/contest/registerParticipant/:accessCode'.replace(':accessCode', accessCode),
        SCHOOL_CATEGORIES: (eventId = ':eventId') => '/api/contest/:eventId/categories'.replace(':eventId', eventId),
        SCHOOL_BY_CODE: (accessCode = ':accessCode') => '/api/public/contest/:accessCode'.replace(':accessCode', accessCode),
        ANSWERS: (questionId) => '/api/contest/answers/:questionId'.replace(':questionId', questionId)
    },
    EXAM: {
        QUESTIONS: '/api/public/exam'
    }
});

export {apiService, setAuthorizationHeader, ENDPOINTS};

