import React, {useEffect, useState} from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BaseForm from "../../../components/common/BaseForm";
import {useHistory} from "react-router-dom";
import {readError} from "../../../utils/utils";
import EventsService from "../../../services/EventsService";
import {useSnackbar} from "notistack";
import {error} from "../../../hoc/GlobalSnackbar/GlobalSnackbar";

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import SchoolIcon from "@material-ui/icons/School";
import {ROUTES} from "../../../utils/navigation";
import ContestService from "../../../services/ContestService";
import SubmitButton from "../../../components/common/SubmitButton";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

export default function () {

    const [loading, setLoading] = useState(true);
    const [registering, setRegistering] = useState([]);
    const [events, setEvents] = useState();
    const [openConsentDialog, setOpenConsentDialog] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();
    const [consent1, setConsent1] = useState(false);
    const [consent2, setConsent2] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [dialogProceedButtonEnabled, setDialogProceedButtonEnabled] = useState(false);
    const {t} = useTranslation('contest-school-list');
    const history = useHistory();

    useEffect(() => {
        if (selectedEvent?.school?.country === 'PL') {
            setDialogProceedButtonEnabled(consent1);
        } else {
            setDialogProceedButtonEnabled(consent1 && consent2);
        }
    }, [consent1, consent2]);


    const sortEvents = (events) => {
        return [...events.sort((a, b) => a.school.name.localeCompare(b.school.name))];
    };

    useEffect(() => {
        EventsService.myEvents()
            .then(result => {
                setEvents(sortEvents(result.data));
            })
            .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
            .finally(() => setLoading(false));
    }, []);

    const handleRegister = () => {
        setRegistering(registering => [selectedEvent.id, ...registering]);
        ContestService.registerSchool(selectedEvent)
            .then(() => {
                const eventCopy = JSON.parse(JSON.stringify(selectedEvent));
                eventCopy.participateInContest = true;
                setEvents(events => sortEvents([eventCopy, ...(events.filter(element => element.id !== selectedEvent.id))]));
                setConsent1(false);
                setConsent2(false);
                setOpenConsentDialog(false);
                handleShowDetails(selectedEvent);
            })
            .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
            .finally(() => setRegistering(registering => registering.filter(element => element !== selectedEvent.id)));
    };

    const handleOpenConsentDialog = (event) => {
        setSelectedEvent(event);
        setOpenConsentDialog(true);
    };

    const handleCancelConsentDialog = () => {
        setConsent1(false);
        setConsent2(false);
        setSelectedEvent(undefined);
        setOpenConsentDialog(false);
    };

    const handleShowDetails = (event) => {
        history.push(ROUTES.CONTEST.ORGANIZER.SCHOOL_CATEGORIES(event.id));
    };

    const Row = (event, index) => {
        return event.confirmed ? (
            <TableRow hover={true} key={`row-${index}`}>
                <TableCell component="th" scope="row">
                    {event.school.name}
                </TableCell>
                <TableCell>{event.mainCoordinator.fullName}</TableCell>
                <TableCell>{event.fullLicenseNumber}</TableCell>
                <TableCell>
                    {event.participateInContest ?
                        <SubmitButton
                            startIcon={<SchoolIcon/>}
                            color='secondary'
                            color='primary'
                            onClick={() => handleShowDetails(event)}
                        >
                            {t('seeButton')}
                        </SubmitButton>
                        :
                        <SubmitButton
                            startIcon={<SchoolIcon/>}
                            color="secondary"
                            onClick={() => handleOpenConsentDialog(event)}
                            loading={registering.includes(event.id)}
                        >
                            {t('registerButton')}
                        </SubmitButton>
                    }
                </TableCell>
            </TableRow>) : null;
    };

    return (
        <>
            {loading ? <CircularProgress/> :
                <>
                    <BaseForm size="lg" Icon={AssignmentIcon} title={t('registerSchoolTitle')}>
                        {(events && events.length) ?
                            <>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('schoolName')}</TableCell>
                                                <TableCell>{t('mainCoordinator')}</TableCell>
                                                <TableCell>{t('licenseNumber')}</TableCell>
                                                <TableCell>{t('action')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {events.map((row, index) => (
                                                Row(row, index)
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                            :
                            <h3>{t('noSchoolRegisteredInTheEvent')}</h3>}
                    </BaseForm>
                    <Dialog open={openConsentDialog} onClose={handleCancelConsentDialog}>
                        <DialogTitle>{t('consentDialogTitle')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Checkbox
                                    onChange={() => setConsent1(!consent1)}
                                    value={consent1}
                                />
                                {t('consent1')}
                            </DialogContentText>
                            {
                                selectedEvent?.school?.country !== 'PL' ?
                                    <>
                                        <DialogContentText>
                                            <Checkbox
                                                onChange={() => setConsent2(!consent2)}
                                                value={consent2}
                                            />
                                            {t('consent2')}
                                        </DialogContentText>
                                        <DialogContentText>
                                            <i>{t('consent2Info')}</i>
                                        </DialogContentText> </> : null
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancelConsentDialog} color="primary">
                                {t('cancelButton')}
                            </Button>
                            <Button onClick={handleRegister} color="primary"
                                    disabled={!dialogProceedButtonEnabled || registering.length}>
                                {t('confirmButton')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
        </>
    );
}
