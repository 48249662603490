import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import {getLanguage} from '../../utils/i18n';
import {Trans} from 'react-i18next';

export default function ({form, t}) {

    const error = form?.errors?.['accountConsent'];
    const accountConsent = t('accountConsent');
    const link = `https://kartygrabowskiego.pl/wmtday/2023/docs/wmtday2023_rules_${getLanguage()}.pdf`;

    return (
        !!accountConsent ?
            <>
                <FormControlLabel
                    error={!!error}
                    control={
                        <Checkbox
                            name="accountConsent"
                            color="primary"
                            required
                            inputRef={form.register({required: true})}
                        />
                    }
                    label={
                        <Trans t={t} i18nKey="accountConsent">
                            text <a href={link} target='_blank' rel='noopener noreferrer'>text</a>
                        </Trans>
                    }
                />
                {!!error ?
                    <FormHelperText error>{error ? t('requiredValidationMessage') : undefined}</FormHelperText> : null}
            </> : null
    );
}