import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PuzzleCard from './PuzzleCard';
import PuzzlesService from '../../services/PuzzlesService';
import CircularProgress from '@material-ui/core/CircularProgress';
import {readError} from '../../utils/utils';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
}));

export default function () {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [puzzles, setPuzzles] = useState([]);
    const [error, setError] = useState();
    const {t} = useTranslation('puzzles-library');

    const handleDataLoaded = results => {
        const data = results[0].data;
        const likes = results[1].data;
        const puzzleList = data.items.map(puzzle => {
            const domain = data.originDomain ? data.originDomain : null;
            const path = data.originPath ? data.originPath : null;
            const thumbnail = puzzle.thumbnail ? puzzle.thumbnail : puzzle.file.replace(/\.[^/.]+$/, '') + data.thumbnailSuffix;
            let extendedPuzzle = {
                ...puzzle,
                'file': domain + path + puzzle.file,
                'thumbnail': domain + path + thumbnail,
                like: !!likes.find(like => like.id === puzzle.id)
            };
            return extendedPuzzle;
        });
        setPuzzles(puzzleList);
    };

    useEffect(() => {
        Promise.all([PuzzlesService.getPuzzles(), PuzzlesService.likes()])
            .then(handleDataLoaded)
            .catch(reason => setError(readError(reason)))
            .finally(() => setLoading(false));
    }, []);

    return (
        loading ? <CircularProgress/> :
            !puzzles.length ? <h3>{t('noPuzzles')}</h3> :
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={2}>
                            {puzzles.map((puzzle) => (
                                <Grid key={puzzle.id} item>
                                    <PuzzleCard
                                        puzzle={puzzle}
                                        t={t}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
    );
}
