import {apiService, ENDPOINTS} from './api';
import {getLanguage} from '../utils/i18n';

const getPuzzles = () => {
    return apiService.get(ENDPOINTS.PUZZLES.ALL(getLanguage()), {headers: {'Authorization': ''}});
};

const like = (puzzleId) => {
    return apiService.post(ENDPOINTS.PUZZLES.LIKE(puzzleId));
};

const dislike = (puzzleId) => {
    return apiService.post(ENDPOINTS.PUZZLES.DISLIKE(puzzleId));
};

const likes = () => {
    return apiService.get(ENDPOINTS.PUZZLES.LIKES);
};

const PuzzlesService = {
    'getPuzzles': getPuzzles,
    'like': like,
    'dislike': dislike,
    'likes': likes
};

export default PuzzlesService;