import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import CategoryIcon from './CategoryIcon';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import GetAppIcon from '@material-ui/icons/GetApp';
import PuzzlesService from '../../services/PuzzlesService';
import FavoriteIcon from '@material-ui/icons/Favorite';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 300,
        minWidth: 300
    },
    media: {
        height: 0,
        paddingTop: '75%', // 16:9
        border: '1px solid lightgray',
        cursor: 'pointer'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}));

export default function ({puzzle, t}) {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [isLiked, setIsLiked] = React.useState(puzzle.like);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleLike = (selected, id) => {
        if (!selected) {
            PuzzlesService.like(id)
                .then(response => setIsLiked(true));
        } else {
            PuzzlesService.dislike(id)
                .then(response => setIsLiked(false));
        }
    };

    const openInNewTab = (url) => {
        window.open(url, '_blank');
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <CategoryIcon category={puzzle?.category} categoryLabel={puzzle?.categoryLabel} t={t}/>
                }
                // action={
                //     <IconButton aria-label="settings">
                //         <MoreVertIcon/>
                //     </IconButton>
                // }
                title={puzzle?.title}
                subheader={puzzle?.level}
            />
            <CardMedia
                onClick={() => openInNewTab(puzzle.file)}
                className={classes.media}
                image={puzzle?.thumbnail}
            />
            {puzzle?.description ?
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {puzzle?.description}
                    </Typography>
                </CardContent> : null}
            <CardActions disableSpacing>
                <Tooltip title={t('like')} aria-label={t('like')}>
                    <IconButton onClick={() => handleLike(isLiked, puzzle.id)}>
                        {isLiked ? <FavoriteIcon style={{color: red[500]}}/> : <FavoriteBorderIcon/>}
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('download')} aria-label={t('download')}>
                    <IconButton onClick={() => openInNewTab(puzzle.file)}>
                        <GetAppIcon/>
                    </IconButton>
                </Tooltip>
                {puzzle?.video ?
                    <Tooltip title={t('video')} aria-label={t('video')}>
                        <IconButton onClick={() => openInNewTab(puzzle.video)}>
                            <YouTubeIcon style={{color: red[500]}}/>
                        </IconButton>
                    </Tooltip> : null}
            </CardActions>
        </Card>
    );
}
