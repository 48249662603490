import React from "react";
import SubmitButton from "../../../components/common/SubmitButton";
import {useHistory} from "react-router-dom";
import {ROUTES} from "../../../utils/navigation";
import AssessmentIcon from '@material-ui/icons/Assessment';
import {useTranslation} from "react-i18next";
import MyPosition from "./MyPosition";

export default function ({children}) {

  const {t} = useTranslation('contest-final-results');
  const history = useHistory();

  const handleSeeDetails = () => {
    history.push(ROUTES.CONTEST.PARTICIPANT.MY_CONTEST);
  };

  return (
    <MyPosition>
      <div style={{padding: '20px 20px', textAlign: 'center'}}>
        <SubmitButton onClick={handleSeeDetails} color='secondary' startIcon={<AssessmentIcon/>}
                      style={{maxWidth: '400px'}}>
          {t('seeDetailsButton')}
        </SubmitButton>
      </div>
    </MyPosition>
  );
}
