import {ThemeProvider} from '@material-ui/core/styles';
import React, {Suspense} from 'react';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import './App.css';
import AnonymousRoute from './components/Route/AnonymousRoute';
import PrivateRoute from './components/Route/PrivateRoute';
import GlobalSnackbar from './hoc/GlobalSnackbar/GlobalSnackbar';
import AccountConfirmation from './pages/AccountConfirmation/AccountConfirmation';
import AccountRecovery from './pages/AccountRecovery/AccountRecovery';
import ContestSchoolDetails from './pages/contest/ContestSchoolDetails/ContestSchoolDetails';
import ContestSchoolList from './pages/contest/ContestSchoolList/ContestSchoolList';
import FinalResults from './pages/contest/FinalResults/FinalResults';
import MyContest from './pages/contest/MyContest/MyContest';
import MyFinalResults from './pages/contest/MyFinalResults/MyFinalResults';
import ParticipantRegistration from './pages/contest/ParticipantRegistration/ParticipantRegistration';
import EditSchoolRegistration from './pages/EditSchoolRegistration/EditSchoolRegistration';
import ExamOnline from './pages/ExamOnline/ExamOnline';
import MyEvents from './pages/MyEvents/MyEvents';
import MyProfile from './pages/MyProfile/MyProfile';
import OrganizerList from './pages/OrganizerList/OrganizerList';
import PasswordChange from './pages/PasswordChange/PasswordChange';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import PuzzlesLibrary from './pages/PuzzlesLibrary/PuzzlesLibrary';
import SchoolRegistration from './pages/SchoolRegistration/SchoolRegistration';
import SignIn from './pages/SignIn/SignIn';
import SignOutLink from './pages/SignIn/SignOutLink';
import SignUp from './pages/SignUp/SignUp';
import StartView from './pages/StartView/StartView';
import i18n from './utils/i18n';
import {ROUTES} from './utils/navigation';
import {theme} from './utils/styling';
import {getBaseUrl} from './utils/utils';

function App() {
    // do not remove this line
    const init = i18n.isInitialized;

    const AdminPanel = React.lazy(() => import('./pages/admin/Admin'));

    return (
        <Suspense fallback="loading">
            <BrowserRouter basename={getBaseUrl()}>
                <ThemeProvider theme={theme}>
                    <GlobalSnackbar>
                        <div className="App">
                            {/*<header>*/}
                            {/*    <ul>*/}
                            {/*        <li><Link to="/">Root</Link></li>*/}
                            {/*        <li><Link to="/sign-in">Sign In</Link></li>*/}
                            {/*        <li><Link to="/sign-up">Sign Up</Link></li>*/}
                            {/*        <li><Link to='/account-recovery'>Account recovery</Link></li>*/}
                            {/*        <li><Link to="/school-registration">Register school</Link></li>*/}
                            {/*        <li><Link to="/account-confirmation">Account confirmation</Link></li>*/}
                            {/*        <li><Link to="/password-reset/:token">Reset password</Link></li>*/}
                            {/*        <li><Link to="/change-password">Change password</Link></li>*/}
                            {/*        <li><Link to="/my-events">My events</Link></li>*/}
                            {/*        <li><Link to="/my-profile">My profile</Link></li>*/}
                            {/*        <li><Link to="/sign-out">Sign Out</Link></li>*/}
                            {/*    </ul>*/}
                            {/*</header>*/}
                            <PrivateRoute path={ROUTES.ROOT} exact component={StartView}/>
                            <PrivateRoute path={ROUTES.MY_EVENTS} component={MyEvents}/>
                            <AnonymousRoute path={ROUTES.SIGN_IN} component={SignIn}/>
                            <AnonymousRoute path={ROUTES.SIGN_UP} component={SignUp}/>
                            <Route path={ROUTES.SIGN_OUT} component={SignOutLink}/>
                            <Route path={ROUTES.ACCOUNT_RECOVERY} component={AccountRecovery}/>
                            <PrivateRoute exact path={ROUTES.REGISTER_SCHOOL} component={SchoolRegistration}/>
                            <PrivateRoute path={ROUTES.EDIT_EVENT()} component={EditSchoolRegistration}/>
                            <PrivateRoute path={ROUTES.MY_PROFILE} component={MyProfile}/>
                            <Route path={ROUTES.ACCOUNT_CONFIRMATION} component={AccountConfirmation}/>
                            <Route path={ROUTES.PASSWORD_RESET} component={PasswordReset}/>
                            <Route exact path={ROUTES.LOCAL_ORGANIZERS} component={OrganizerList}/>
                            <Route path={ROUTES.COUNTRY_LOCAL_ORGANIZERS} component={OrganizerList}/>
                            <Route exact path={ROUTES.LOCAL_ORGANIZERS_ARCHIVE} component={OrganizerList}/>
                            <Route path={ROUTES.COUNTRY_LOCAL_ORGANIZERS_ARCHIVE} component={OrganizerList}/>
                            <PrivateRoute path={ROUTES.CHANGE_PASSWORD} component={PasswordChange}/>
                            <PrivateRoute path={ROUTES.PUZZLES_LIBRARY} component={PuzzlesLibrary}/>

                            <Switch>
                                <PrivateRoute exact path={ROUTES.CONTEST.PARTICIPANT.MY_RESULTS}
                                              component={MyFinalResults}/>
                                <Route exact path={ROUTES.CONTEST.PARTICIPANT.FINAL_RESULTS}
                                       component={FinalResults}/>
                                <PrivateRoute exact path={ROUTES.CONTEST.ORGANIZER.SCHOOL_LIST}
                                              component={ContestSchoolList}/>
                                <PrivateRoute path={ROUTES.CONTEST.ORGANIZER.SCHOOL_CATEGORIES()}
                                              component={ContestSchoolDetails}/>
                                <PrivateRoute path={ROUTES.CONTEST.MY_PROFILE}
                                              component={MyProfile}/>
                                <PrivateRoute exact path={ROUTES.CONTEST.PARTICIPANT.MY_CONTEST} component={MyContest}/>
                                <Route exact path={ROUTES.CONTEST.PARTICIPANT.REGISTER('')}
                                       component={ParticipantRegistration}/>
                                <Route path={ROUTES.CONTEST.PARTICIPANT.REGISTER()}
                                       component={ParticipantRegistration}/>
                            </Switch>

                            <Route path={ROUTES.EXAM_ONLINE} component={ExamOnline}/>

                            <PrivateRoute path={ROUTES.ADMIN.ROOT}>
                                <React.Suspense fallback={<div>Loading...</div>}>
                                    <AdminPanel/>
                                </React.Suspense>
                            </PrivateRoute>
                        </div>
                    </GlobalSnackbar>
                </ThemeProvider>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
