import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import Looks5Icon from "@material-ui/icons/Looks5";
import SubmitButton from "../../components/common/SubmitButton";

export default function ({t, resultList, time, timeIsUp}) {

    const [results] = useState(resultList);

    useEffect(() => {

    }, [results])

    const indexToIconMapping = (index, correct) => {
        switch (index) {
            case 0:
                return <LooksOneIcon fontSize='large' style={correct ? {color: 'green'} : {color: 'red'}}/>;
            case 1:
                return <LooksTwoIcon fontSize='large' style={correct ? {color: 'green'} : {color: 'red'}}/>;
            case 2:
                return <Looks3Icon fontSize='large' style={correct ? {color: 'green'} : {color: 'red'}}/>;
            case 3:
                return <Looks4Icon fontSize='large' style={correct ? {color: 'green'} : {color: 'red'}}/>;
            case 4:
                return <Looks5Icon fontSize='large' style={correct ? {color: 'green'} : {color: 'red'}}/>;
        }
    };

    return (
        <>
            {
                resultList.succeeded ?
                    <>
                        <h2 style={{textAlign: 'center'}}>{t('success')}</h2>
                        <h3>{t('yourTime', {time})}</h3>
                        <SubmitButton onClick={() => window.location.reload(false)}>
                            {t('playAgain')}
                        </SubmitButton>
                    </> :
                    <>
                        <h2>{timeIsUp ? t('failureTimeIsUp') : t('failure')}</h2>
                        <SubmitButton onClick={() => window.location.reload(false)}>
                            {t('tryAgain')}
                        </SubmitButton>
                    </>
            }
            <Grid container spacing={1}>
                {resultList && resultList.taskResults && resultList.taskResults.map((item, index) =>
                    <>
                        <Grid item xs={2} sm={1}>{indexToIconMapping(index, item.valid)}</Grid>
                        <Grid item xs={4} sm={2} style={{alignSelf: 'center'}}>{t('question')}</Grid>
                        <Grid item xs={6} sm={2} style={{alignSelf: 'center'}}><b>{item.task}</b></Grid>
                        <Grid item xs={6} sm={4} style={{alignSelf: 'center'}}>{t('yourAnswer')}</Grid>
                        <Grid item xs={3} sm={1} style={{alignSelf: 'center'}}><b>{item.answer}</b></Grid>
                        <Grid item xs={3} sm={2} style={{alignSelf: 'center'}}><b>{item.valid ? t('correctAnswer') : t('wrongAnswer')}</b></Grid>
                    </>
                )}
            </Grid>
        </>
    );
}
