import Grid from '@material-ui/core/Grid';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import EmailFieldComponent from '../../components/common/EmailFieldComponent';
import Errors from '../../components/common/Errors';
import PhoneFieldComponent from '../../components/common/PhoneFieldComponent';
import SubmitButton from '../../components/common/SubmitButton';
import TextFieldComponent from '../../components/common/TextFieldComponent';
import {useStyles} from '../../utils/styling';
import {readError} from '../../utils/utils';
import CoordinatorConsent from './CoordinatorConsent';
import CountryFieldComponent from './CountryFieldComponent';
import Position from './Position';
import RulesConsent from './RulesConsent';
import Checkbox from "@material-ui/core/Checkbox";

export default function ({submitLabel, onSubmit, form}) {
    const classes = useStyles();
    const {t} = useTranslation('event-details');
    const [error, setError] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);

    const handleSubmit = data => {
        let isError = false;
        const coordinators = [];
        data.coordinators.forEach((item, index) => {
            if (item.email && coordinators.includes(item.email)) {
                form.setError(`coordinators[${index}].email`, {
                    message: t('alreadyCoordinator')
                });
                isError = true;
            } else {
                coordinators.push(item.email);
            }
            if (!item.fullName && !!item.email) {
                form.setError(`coordinators[${index}].fullName`, {
                    type: 'required'
                });
                isError = true;
            }
            if (!!item.fullName && !item.email) {
                form.setError(`coordinators[${index}].email`, {
                    type: 'required'
                });
                isError = true;
            }
        });
        if (isError) {
            setError({statusText: t('coordinatorsError')});
            return;
        }
        setSubmitting(true);
        onSubmit(data, () => {
        }, reason => setError(readError(reason)), () => setSubmitting(false));
    };

    const renderCoordinatorRow = (index, isRequired = false) => {
        return (<Grid container item xs={12} spacing={3} key={index}>
            <Grid item xs={4} key={`name-${index}`}>
                <TextFieldComponent
                    name={`coordinators[${index}].fullName`}
                    label={t('fullName')}
                    form={form}
                />
            </Grid>
            <Grid item xs={4} key={`email-${index}`}>
                <EmailFieldComponent
                    name={`coordinators[${index}].email`}
                    label={t('email')}
                    required={isRequired}
                    form={form}
                />
            </Grid>
            <Grid item xs={4} key={`phone-${index}`}>
                <PhoneFieldComponent
                    name={`coordinators[${index}].phoneNumber`}
                    label={t('phoneNumber')}
                    form={form}
                />
            </Grid>
        </Grid>);
    };

    return (
        <form className={classes.form} noValidate onSubmit={form.handleSubmit(handleSubmit)} autoComplete="off">
            <h1>{t('schoolDataSection')}</h1>
            <h2>{t('basicDataSubsection')}</h2>
            <Grid container spacing={2}>
                <TextFieldComponent
                    name='school.name'
                    label={t('schoolName')}
                    multiline
                    rows={2}
                    required
                    form={form}
                />
                <CountryFieldComponent
                    name='school.country'
                    label={t('country')}
                    form={form}
                    required/>
                <TextFieldComponent
                    name="school.city"
                    label={t('city')}
                    required
                    inputProps={{
                        autoComplete: 'new-password'
                    }}
                    form={form}
                />
                <TextFieldComponent
                    name="school.website"
                    label={t('website')}
                    form={form}
                />
                <PhoneFieldComponent
                    name="school.phoneNumber"
                    label={t('phoneNumber')}
                    inputProps={{
                        autoComplete: 'new-password'
                    }}
                    form={form}
                />
            </Grid>
            <h2>{t('addressSubsection')}</h2>
            <Grid container spacing={2}>
                <TextFieldComponent
                    name="school.address"
                    label={t('addressLabel')}
                    placeholder={t('addressPlaceholder')}
                    multiline
                    rows={4}
                    required
                    inputProps={{
                        autoComplete: 'new-password'
                    }}
                    form={form}
                />
            </Grid>
            <h2>{t('personInCharge')}</h2>
            <Grid container spacing={2}>
                <Position
                    name='school.managerPosition'
                    label={t('managerPosition')}
                    form={form}
                />
                <TextFieldComponent
                    name="school.managerFullName"
                    label={t('managerFullName')}
                    required
                    form={form}
                    inputProps={{
                        autoComplete: 'new-password'
                    }}
                />
            </Grid>
            <div className={classes.root}>
                <h1>{t('coordinatorsSection')}</h1>
                <h2>{t('mainCoordinator')}</h2>
                {[0].map((item) => renderCoordinatorRow(item, true))}
                <h2>{t('additionalCoordinators')}</h2>
                {[1, 2, 3, 4].map((item) => renderCoordinatorRow(item))}
            </div>
            <Grid container spacing={2}>
                <CoordinatorConsent form={form} t={t}/>
                <RulesConsent form={form} t={t}/>
                <Checkbox
                  name="previousYear"
                  hidden
                  className='hidden'
                  inputRef={form.register({required: false})}
                />
            </Grid>
            <div className={classes.wrapper}>
                <Errors onClose={() => setError(undefined)} error={error}/>
                <SubmitButton classes={classes} loading={isSubmitting}>
                    {submitLabel}
                </SubmitButton>
            </div>
        </form>
    );
}
