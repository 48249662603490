import React, {useState} from "react";
import EnterContestId from "./EnterContestId";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import SchoolDetailsStep from "./SchoolDetailsStep";
import ChooseParticipant from "./ChooseParticipant";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {ROUTES} from "../../../utils/navigation";
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {Trans, useTranslation} from "react-i18next";
import ContestRules from "./ContestRules";
import AcceptRules from "./AcceptRules";

export default function ({match}) {

    const globalState = useState({'accessCode': match.params.accessCode});
    const [state] = globalState;
    const {t} = useTranslation('contest-registration');
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const step0 = {
        title: t('step0TitleFindSchool'),
        content: (<EnterContestId t={t} globalState={globalState} nextStep={handleNext}/>)
    };

    const step1 = {
        title: t('step1ConfirmSchool'),
        content: (
            <SchoolDetailsStep t={t} globalState={globalState} nextStep={handleNext} prevStep={handleBack}/>
        )
    }

    const step2 = {
        title: t('step2AcceptRules'),
        content: (<AcceptRules t={t} globalState={globalState} nextStep={handleNext} prevStep={handleBack}/>)
    }

    const step3 = {
        title: t('step3ConfirmParticipant'),
        content: (<ChooseParticipant t={t} globalState={globalState} nextStep={handleNext} prevStep={handleBack}/>)
    }

    const steps = [step0, step1, step2, step3];

    const linkToContest = (
        <Link component={RouterLink} to={ROUTES.CONTEST.PARTICIPANT.MY_CONTEST}>
            {t('goToContestButton')}
        </Link>
    );

    return (
        <div>
            {t('registrationIsClosed')}
            {/*<ContestRules/>*/}
            {/*<Stepper activeStep={activeStep} orientation="vertical">*/}
            {/*    {steps.map((step, index) => (*/}
            {/*        <Step key={step.title}>*/}
            {/*            <StepLabel>{step.title}</StepLabel>*/}
            {/*            <StepContent>*/}
            {/*                {step.content}*/}
            {/*            </StepContent>*/}
            {/*        </Step>*/}
            {/*    ))}*/}
            {/*</Stepper>*/}
            {/*{activeStep === steps.length && (*/}
            {/*    <Paper square elevation={0}>*/}
            {/*        {state.newUser ?*/}
            {/*            <>*/}
            {/*                <Trans t={t} i18nKey="registeredNewUser">*/}
            {/*                    <p>You have been registered</p>*/}
            {/*                    <p>Email to {{email: state.user.email}} sent</p>*/}
            {/*                </Trans>*/}

            {/*                {linkToContest}*/}
            {/*            </> :*/}
            {/*            <>*/}
            {/*                <Typography>{t('registeredExistingUser')}*/}
            {/*                    {linkToContest}.*/}
            {/*                </Typography>*/}
            {/*            </>*/}
            {/*        }*/}
            {/*    </Paper>*/}
            {/*)}*/}
        </div>
    );
}
