import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StarIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {Container} from '@material-ui/core';
import AddNewCoordinator from './AddNewCoordinator';
import {useStyles} from '../../utils/styling';
import {apiService, ENDPOINTS} from '../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getAuthenticatedUser} from '../../services/auth';
import {useTranslation} from 'react-i18next';
import {readError} from '../../utils/utils';
import Tooltip from '@material-ui/core/Tooltip';
import Errors from '../../components/common/Errors';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import CoordinatorPolicy from '../../utils/CoordinatorPolicy';
import EditIcon from '@material-ui/icons/Edit';
import {useHistory} from 'react-router-dom';
import {ROUTES} from '../../utils/navigation';

export default function Row({event}) {
    const {t} = useTranslation('my-events');
    const [open, setOpen] = useState(true);
    const [addNew, setAddNew] = useState(false);
    const [coordinators, setCoordinators] = useState(event.coordinators);
    const [removing, setRemoving] = useState({});
    const [canManageEvent, setCanManageEvent] = useState(false);
    const [error, setError] = useState();
    const [remove, setRemove] = useState();
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => setCanManageEvent(CoordinatorPolicy.canManageEvent(getAuthenticatedUser(), event)), [event]);

    const handleAddNewCoordinator = (data, form, callback) => {
        if (coordinators.some(user => user.email === data.email)) {
            form.setError('email', {
                message: t('alreadyCoordinator')
            });
            callback();
            return;
        }
        if (coordinators.length >= 5) {
            form.setError('email', {
                message: t('tooManyCoordinators')
            });
            callback();
            return;
        }
        apiService.post(ENDPOINTS.REGISTRATION.AUTH.ADD_COORDINATOR(event.id), data)
            .then(response => {
                setCoordinators([...coordinators, response.data]);
                setAddNew(false);
            })
            .catch(reason => {
                setError(readError(reason));
            })
            .finally(callback);
    };

    const handleRemoveCoordinator = () => {
        setRemoving({...removing, [remove.coordinatorId]: true});

        apiService.delete(ENDPOINTS.REGISTRATION.AUTH.REMOVE_COORDINATOR(remove.eventId, remove.coordinatorId))
            .then(response => {
                const filtered = coordinators.filter(user => user.id !== remove.coordinatorId);
                setCoordinators(filtered);
            })
            .catch(reason => {
                setError(readError(reason));
            })
            .finally(() => {
                setRemoving({...removing, [remove.coordinatorId]: false});
                setRemove(undefined);
            });
    };

    const handleEdit = () => {
        history.push(ROUTES.EDIT_EVENT(event.id));
    };

    return (
        <>
            <TableRow className={classes.rowHeader} onClick={() => setOpen(!open)} hover={true}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {event.school.name}
                </TableCell>
                <TableCell>{event.mainCoordinator.fullName}</TableCell>
                <TableCell>{event.confirmed ? event.fullLicenseNumber :
                    <Tooltip title={t('awaitingLicenseTooltip')}><ScheduleIcon/></Tooltip>}</TableCell>
                <TableCell>
                    <Button
                        startIcon={<EditIcon/>}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={handleEdit}
                    >
                        {t('editActionTooltip')}
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography gutterBottom component="div">
                                {t('coordinatorsHeading')}
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {canManageEvent ?
                                            <TableCell>{t('actions')}</TableCell> : null}
                                        <TableCell>{t('fullName')}</TableCell>
                                        <TableCell>{t('email')}</TableCell>
                                        <TableCell>{t('role')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {coordinators.map((coordinator) => (
                                        <TableRow key={coordinator.email}>
                                            {canManageEvent ?
                                                <TableCell>
                                                    {removing[coordinator.id] ? <CircularProgress/> :
                                                        <Tooltip title={t('removeActionTooltip')}>
                                                            <IconButton color="primary" component="span"
                                                                        onClick={() => setRemove({
                                                                            coordinatorId: coordinator.id,
                                                                            eventId: event.id
                                                                        })}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>}
                                                </TableCell> : null}
                                            <TableCell component="th" scope="row">
                                                {coordinator.fullName}
                                            </TableCell>
                                            <TableCell>{coordinator.email}</TableCell>
                                            <TableCell>{(coordinator.email === event.mainCoordinator.email) ?
                                                <Tooltip title={t('roleMain')}><StarIcon/></Tooltip> : null}</TableCell>
                                        </TableRow>
                                    ))}
                                    {addNew ?
                                        <>
                                            <Errors onClose={() => setError(undefined)} error={error}/>
                                            <AddNewCoordinator
                                                onCancel={() => setAddNew(false)}
                                                onSave={handleAddNewCoordinator}
                                                t={t}
                                            /></> : null}
                                </TableBody>
                            </Table>
                            <ConfirmDialog show={!!remove} title={t('confirmRemoveCoordinatorTitle')}
                                           cancelLabel={t('cancelRemove')}
                                           confirmLabel={t('confirmRemove')}
                                           onCancel={() => setRemove(undefined)}
                                           onConfirm={handleRemoveCoordinator}
                            >
                            </ConfirmDialog>
                            {canManageEvent ?
                                <Container maxWidth="xs">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        startIcon={<PersonAddIcon/>}
                                        onClick={event => setAddNew(true)}
                                    >
                                        {t('addNewCoordinatorButton')}
                                    </Button>
                                </Container>
                                : null}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

