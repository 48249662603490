import React from "react";
import {useTranslation} from "react-i18next";


export default function ({category, country}) {

    const {t} = useTranslation('contest-school-details');

    const countryCode = country === 'PL' ? 'PL' : 'OTHER';

    return (
        <>
            <b>{category}</b>
            <br></br>
            <i>{t(`category-${category}-${countryCode}`)}</i>
        </>
    );
}
