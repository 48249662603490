import {countries_en, countries_es, countries_pl, countries_ru} from './contryLists';

const countryByCode = (code, countries) => {
    return countries.find(item => item.code === code);
};

const countryByName = (name, countries) => {
    return countries.find(item => item.label === name);
};

const countryListObject = (countryList) => {
    return {
        list: countryList,
        getByCode: (code) => countryByCode(code, countryList),
        getByName: (name) => countryByName(name, countryList)
    };
};

export const COUNTRIES = {
    en: countryListObject(countries_en),
    pl: countryListObject(countries_pl),
    es: countryListObject(countries_es),
    ru: countryListObject(countries_ru)
};

export {countryByCode, countryByName};