import React, {useEffect, useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import ContestService from "../../../services/ContestService";
import {readError} from "../../../utils/utils";
import {error} from "../../../hoc/GlobalSnackbar/GlobalSnackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContestDetails from "./ContestDetails";
import TabPanel from "./TabPanel";
import QuestionPanel from "./QuestionPanel";
import QualificationInfo from "./QualificationInfo";

export default function () {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [endDate, setEndDate] = useState(new Date());
  const [results, setResults] = useState({});
  const [currentTime, setCurrentTime] = useState();
  const {t} = useTranslation('contest-my');


  const loadData = () => {
    setLoading(true);
    Promise.all([
      ContestService.myContest(),
      // ContestService.getStartTime(),
      ContestService.myResults(),
      ContestService.getCurrentTime(),
    ])
      .then(result => {
        setData(result[0].data);
        // setEndDate(new Date(result[1].data));
        setResults(result[1].data);
        setCurrentTime(result[2].data);
      })
      .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (data?.questions) {
      // data.questions.forEach((question, index) => {
      //   const now = new Date().getTime();
      //   if (now >= question.startDate && now < question.endDate) {
      //     setActiveTabIndex(index + 1);
      //   }
      // });
      setActiveTabIndex(4);
    }
  }, [data]);


  const handleChangeActiveTab = (event, newActiveIndex) => {
    setActiveTabIndex(newActiveIndex);
  };

  const getQuestion = (index) => {
    return data && data?.questions && data.questions[index];
  }

  return (
    loading ? <CircularProgress/> :
      <div>
        <AppBar position="static" color="default">
          <Tabs
            value={activeTabIndex}
            onChange={handleChangeActiveTab}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab label={t('tabDetails')} icon={<AssignmentIcon/>}/>
            <Tab label={t('tabDay1')} icon={<LooksOneIcon/>}/>
            <Tab label={t('tabDay2')} icon={<LooksTwoIcon/>}/>
            <Tab label={t('tabDay3')} icon={<Looks3Icon/>}/>
            <Tab label={t('tabFinal')} icon={<EmojiEventsIcon/>}/>
          </Tabs>
        </AppBar>
        <TabPanel value={activeTabIndex} index={0}>
          <ContestDetails results={results} category={data.category} school={data.school} endDate={endDate} t={t}/>
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          {getQuestion(0) ?
            <QuestionPanel t={t} school={data.school} category={data.category} question={data.questions[0]}
                           results={results}
                           reloadDataHandler={loadData}/> : null}
        </TabPanel>
        <TabPanel value={activeTabIndex} index={2}>
          {getQuestion(1) ?
            <QuestionPanel t={t} school={data.school} category={data.category} question={data.questions[1]}
                           results={results}
                           reloadDataHandler={loadData}/> : null}
        </TabPanel>
        <TabPanel value={activeTabIndex} index={3}>
          {getQuestion(2) ?
            <QuestionPanel t={t} school={data.school} category={data.category} question={data.questions[2]}
                           results={results}
                           reloadDataHandler={loadData}/> : null}
        </TabPanel>
        <TabPanel value={activeTabIndex} index={4}>
          {getQuestion(3) ?
            <>
              <QualificationInfo t={t} results={results}/>
              <QuestionPanel t={t} school={data.school} category={data.category} question={data.questions[3]}
                             reloadDataHandler={loadData}
                             results={results}
                             currentTime={currentTime}

              />
            </>
            : null}
        </TabPanel>
      </div>
  );
}
