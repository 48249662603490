import React, {useEffect, useState} from "react";
import ContestService from "../../../services/ContestService";
import {useSnackbar} from "notistack";
import {readError} from "../../../utils/utils";
import {error} from "../../../hoc/GlobalSnackbar/GlobalSnackbar";
import EventsService from "../../../services/EventsService";
import BaseForm from "../../../components/common/BaseForm";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CategoriesTable from "./CategoriesTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import SubmitButton from "../../../components/common/SubmitButton";
import {useHistory} from 'react-router-dom';
import {ROUTES} from "../../../utils/navigation";
import {useTranslation} from "react-i18next";

export default function ({match}) {

    const [loading, setLoading] = useState(true);
    const [eventId] = useState(match.params.eventId);
    const [event, setEvent] = useState();
    const [categories, setCategories] = useState();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const {t} = useTranslation('contest-school-details');


    const sortCategories = (items) => {
        return [...items.sort((a, b) => a.name.localeCompare(b.name))];
    };

    const handleDataLoaded = (results) => {
        setEvent(results[0].data);
        setCategories(sortCategories(results[1].data));
    };

    useEffect(() => {
        Promise.all([
            EventsService.getEvent(eventId),
            ContestService.getCategories(eventId),
        ])
            .then(handleDataLoaded)
            .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
            .finally(() => setLoading(false));
    }, [eventId]);

    return (
        <>
            {loading ? <CircularProgress/> :
                categories ?
                    <BaseForm size="lg" Icon={AssignmentIcon} title={event.school.name}>
                        {(categories && categories.length) ?
                            <>
                                <br></br>
                                <CategoriesTable t={t} school={event.school} categories={categories}/>
                            </>
                            :
                            <h3>{t('schoolNotRegisteredForTheContest')}</h3>}
                        <br></br>
                        <SubmitButton onClick={() => history.push(ROUTES.CONTEST.ORGANIZER.SCHOOL_LIST)}>
                            {t('backButton')}
                        </SubmitButton>
                    </BaseForm> : null}
            <input type="text" id="clipboard"
                   style={{position: 'fixed', top: '-1000px'}}
            />
        </>
    );
}
