import React, {useRef, useState} from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {getBaseUrl} from "../../../utils/utils";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {useSnackbar} from "notistack";
import {success} from "../../../hoc/GlobalSnackbar/GlobalSnackbar";
import CategoryInfo from "./CategoryInfo";
import SubmitButton from "../../../components/common/SubmitButton";
import ResultsDialog from "./ResultsDialog";

export default function ({t, school, category}) {

  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const {enqueueSnackbar} = useSnackbar();

  const textInput = useRef(null);

  const createLink = (code) => {
    return `https://wmtday.org${getBaseUrl()}/contest/register/${code}`;
  };

  const copyToClipboard = (event, code) => {
    const clipboardText = document.getElementById("clipboard");
    clipboardText.value = createLink(code);
    clipboardText.select();
    clipboardText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    enqueueSnackbar(t('codeCopied'), success);
    event.preventDefault();
  };

  const handleSeeResults = (category) => {
    setSelectedCategory(category);
  };

  const handleResultsClose = () => {
    setSelectedCategory({});
  };

  return (
    <>
      <TableRow onClick={() => setOpen(!open)} hover={true}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <CategoryInfo category={category.name} country={school.country}/>
        </TableCell>
        <TableCell>{category.accessCode.toUpperCase()}</TableCell>
        <TableCell>
          <IconButton color="primary" component="span" onClick={(event) => copyToClipboard(event, category.accessCode)}>
            <FileCopyIcon/>
          </IconButton>
        </TableCell>
        <TableCell>{category.participants.length}</TableCell>
        <TableCell>
          <SubmitButton
            color='secondary'
            onClick={() => handleSeeResults(category)}>
            {t('action')}
          </SubmitButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {t('registeredParticipants')}
              </Typography>
              <b>{t('Link')}:</b> {createLink(category.accessCode)}
              {category.participants.length ?
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('fullName')}</TableCell>
                      <TableCell>{t('accountType')}</TableCell>
                      <TableCell>{t('className')}</TableCell>
                      <TableCell>{t('yearOfBirth')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {category.participants.map((participant) => (
                      <TableRow key={participant.email}>
                        <TableCell component="th" scope="row">
                          {participant.fullName}
                        </TableCell>
                        <TableCell>{t(participant.accountType)}</TableCell>
                        <TableCell>{participant.className}</TableCell>
                        <TableCell>{participant.yearOfBirth ? participant.yearOfBirth : '?'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                : <p>{t('noParticipants')}</p>}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {
        selectedCategory ? <ResultsDialog t={t} category={selectedCategory} onClose={handleResultsClose}/> : null
      }
    </>
  );
}

