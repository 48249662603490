import React, {useEffect, useState} from 'react';
import {getAuthenticatedUser} from '../../services/auth';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import {useStyles} from '../../utils/styling';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import EmailFieldComponent from '../../components/common/EmailFieldComponent';
import {apiService, ENDPOINTS} from '../../services/api';
import BaseForm from '../../components/common/BaseForm';
import SubmitButton from '../../components/common/SubmitButton';
import StatusAlert from '../../components/common/StatusAlert';
import {readError} from '../../utils/utils';
import Errors from '../../components/common/Errors';

export default function AccountRecovery() {
    const classes = useStyles();
    const form = useForm();
    const {t} = useTranslation('account-recovery');


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        setEmail(getAuthenticatedUser() || '');
    }, []);

    const onSubmit = (data) => {
        setLoading(true);
        apiService.post(ENDPOINTS.USER.RESET_PASSWORD, data)
            .then(response => setEmailSent(true))
            .catch(reason => {
                const error = readError(reason);
                if (error.code === 'User not found') {
                    form.setError('email', {
                        message: error.statusText
                    });
                } else {
                    setError(error);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };


    return (
        <BaseForm Icon={HelpOutlinedIcon} title={t('title')}>
            {emailSent ?
                <StatusAlert show={emailSent} title={t('emailSentTitle')}>
                    {t('emailSent', {email: email})}
                </StatusAlert>
                :
                <form className={classes.form} noValidate onSubmit={form.handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item>
                            <p>{t('description')}</p>
                        </Grid>
                    </Grid>
                    <EmailFieldComponent
                        name="email"
                        value={email}
                        required
                        autoFocus
                        onChange={handleEmailChange}
                        form={form}
                    />
                    <Errors onClose={() => setError(undefined)} error={error}/>
                    <SubmitButton classes={classes} loading={loading}>
                        {t('submit')}
                    </SubmitButton>
                </form>
            }
        </BaseForm>
    );
}
