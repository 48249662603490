import React, {useState} from 'react';
import MaterialTable from 'material-table';
import tableIcons from '../../../components/common/tableIcons';
import {getLanguage} from '../../../utils/i18n';
import {COUNTRIES} from "../../../utils/countries/countries";
import Typography from "@material-ui/core/Typography";

export default function ({t, results}) {

  const [countries] = useState(COUNTRIES[getLanguage()]);

  const countryStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    gridColumnGap: '10px'
  };

  const calculateTime = (timeInSeconds) => {
    const hours = Math.floor((timeInSeconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeInSeconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeInSeconds % (1000 * 60)) / 1000);

    return (`${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`);
  };

  const getColor = (place) => {
    return place <= 10 ? 'green' : 'black';
  };

  return (
    <div style={{fontSize: '75%'}}>
      <MaterialTable icons={tableIcons}
                     title=''
                     localization={{
                       toolbar: {
                         searchTooltip: t('searchTooltip'),
                         searchPlaceholder: t('searchPlaceholder')
                       }
                     }}
                     columns={[
                       {
                         title: t('place'),
                         field: 'place',
                         customSort: (a, b) => a.place - b.place,
                         render: rowData => <Typography variant="h4" component="h3" gutterBottom
                                                        style={{color: getColor(rowData.place)}}>
                           <b>{rowData.place}</b>
                         </Typography>
                       },
                       {
                         title: t('finalPoints'),
                         field: 'finalPoints',
                         customSort: (a, b) => a.finalPoints - b.finalPoints,
                         render: rowData => rowData.finalPoints ? rowData.finalPoints : t('didntParticipate')
                       },
                       {
                         title: t('finalTime'),
                         field: 'finalTime',
                         customSort: (a, b) => a.finalTime - b.finalTime,
                         render: rowData => calculateTime(rowData.finalTime)
                       },
                       {title: t('schoolName'), field: 'schoolName'},
                       {title: t('city'), field: 'city'},
                       {
                         title: t('country'),
                         field: 'countryCode',
                         render: rowData => <div
                           style={countryStyle}>
                           <img
                             src={`https://kartygrabowskiego.pl/wmtday/organizers/countries/${rowData?.countryCode.toLowerCase()}.png`}
                             alt=''
                           />
                           <span>{countries.getByCode(rowData?.countryCode)?.label}</span>
                         </div>
                       },
                     ]}
                     data={results}
                     options={{
                       // filtering: true,
                       emptyRowsWhenPaging: false,
                       doubleHorizontalScroll: true,
                       pageSize: 50,
                       pageSizeOptions: [50, 100, 200],
                       selection: false
                     }}
      />
    </div>
  );
}
