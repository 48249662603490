import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Errors from '../../components/common/Errors';
import tableIcons from '../../components/common/tableIcons';
import { apiService, ENDPOINTS } from '../../services/api';
import { COUNTRIES } from '../../utils/countries/countries';
import { getLanguage } from '../../utils/i18n';
import { readError } from '../../utils/utils';

export default function ({match}) {
    const {t} = useTranslation('organizer-list');
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState({});
    const [events, setEvents] = useState([]);
    const [error, setError] = useState();
    const [countryCode] = useState(match.params.countryCode);

    useEffect(() => {
        setLoading(true);
        setCountries(COUNTRIES[getLanguage()]);

        let endpoint = ENDPOINTS.REGISTRATION.PUBLIC.CONFIRMED_EVENTS;
        if (match.path.startsWith('/archive/') && match.params.year) {
          endpoint = ENDPOINTS.REGISTRATION.PUBLIC.CONFIRMED_EVENTS_ARCHIVE(match.params.year);
        }

        apiService.get(endpoint)
            .then(response => {
                const events = response.data;
                const filteredEvents = (countryCode) ?
                    events.filter(value => value.school.country.toLowerCase() === countryCode) : events;
                setEvents(filteredEvents);
            })
            .catch(reason => setError(readError(reason)))
            .finally(() => setLoading(false));
    }, [countryCode]);


    const countryStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        gridColumnGap: '10px'
    };

    return (
        loading ? <CircularProgress/> :
            error ? <Errors onClose={() => setError(undefined)} error={error}/> :
                <>
                    <div style={{fontSize: '75%'}}>
                        <MaterialTable icons={tableIcons}
                                       title=''
                                       localization={{
                                           toolbar: {
                                               searchTooltip: t('searchTooltip'),
                                               searchPlaceholder: t('searchPlaceholder')
                                           }
                                       }}
                                       columns={[
                                           {
                                               title: t('licenseNumber'),
                                               field: 'fullLicenseNumber',
                                               customSort: (a, b) => a.licenseNumber - b.licenseNumber
                                           },
                                           {
                                               title: t('country'),
                                               field: 'school.country',
                                               render: rowData => <div
                                                   style={countryStyle}>
                                                   <img
                                                       src={`https://kartygrabowskiego.pl/wmtday/organizers/countries/${rowData?.school?.country.toLowerCase()}.png`}
                                                       alt=''
                                                   />
                                                   <span>{countries.getByCode(rowData?.school?.country)?.label}</span>
                                               </div>
                                           },
                                           {title: t('organizer'), field: 'school.name'},
                                           {title: t('city'), field: 'school.city'}
                                       ]}
                                       data={events}
                                       options={{
                                           // filtering: true,
                                           emptyRowsWhenPaging: false,
                                           doubleHorizontalScroll: true,
                                           pageSize: 20,
                                           pageSizeOptions: [20, 50, 100],
                                           selection: false
                                       }}
                        />
                    </div>
                </>
    );
}
