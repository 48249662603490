import React, {useEffect, useState} from "react";
import Countdown from "../../components/Countdown";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import Looks5Icon from "@material-ui/icons/Looks5";
import Grid from "@material-ui/core/Grid";
import SubmitButton from "../../components/common/SubmitButton";
import Paper from "@material-ui/core/Paper";
import TextFieldComponent from "../../components/common/TextFieldComponent";

export default function ({t, tasks, finished, endDate}) {

    const [answer, setAnswer] = useState('');
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [results, setResults] = useState({tasks: []});
    const [questions, setQuestions] = useState([])
    const [timeIsUp, setTimeIsUp] = useState(false)

    useEffect(() => {
        if (tasks) {
            setQuestions(tasks.map(task => task.split('?')));
        }
    }, [tasks]);

    const handleNextQuestion = () => {
        const answers = results.tasks.slice();
        answers.push({
            task: tasks[activeQuestion],
            answer
        });
        setResults({...results, tasks: answers});
        setAnswer('');
        if (activeQuestion === 4) {
            finished({...results, tasks: answers});
        } else {
            setActiveQuestion(activeQuestion + 1);
        }
    };

    useEffect(() => {
        if (timeIsUp) {
            finished(results, true);
        }
    }, [timeIsUp]);

    const timeIsUpHandler = () => {
        setTimeIsUp(true);
    }

    const textItem = (text) => (
        <Paper elevation={0}>
            <p style={{fontSize: '2em'}}>{text}</p>
        </Paper>
    );

    const questionItem = () => (
        <Paper elevation={0}>
            <TextFieldComponent
                autoFocus
                style={{
                    maxWidth: '60px',
                    marginBottom: '15px',
                    marginLeft: '5px',
                    marginRight: '5px'
                }}
                onKeyPress={event => {
                    if (event.key === 'Enter' && answer) {
                        handleNextQuestion();
                    }
                }}
                value={answer}
                onChange={event => {
                    const isInt = /^[0-9\b]+$/;
                    if (event.target.value === '' || isInt.test(event.target.value)) {
                        setAnswer(event.target.value);
                    }
                }}
            />
        </Paper>
    );

    const iconColour = (index) => {
        if (activeQuestion === index) {
            return {color: 'orange'};
        } else if (activeQuestion < index) {
            return {color: 'gray'};
        } else if (activeQuestion > index) {
            return {color: 'blue'};
        }
    }

    return (
        questions.length ?
            <>
                <Countdown t={t} date={endDate} showDays={false} showHours={false}
                           showMinutes={false}
                           onTimeIsUp={timeIsUpHandler}/>
                <div>
                    <LooksOneIcon fontSize='large' style={iconColour(0)}/>
                    <LooksTwoIcon fontSize='large' style={iconColour(1)}/>
                    <Looks3Icon fontSize='large' style={iconColour(2)}/>
                    <Looks4Icon fontSize='large' style={iconColour(3)}/>
                    <Looks5Icon fontSize='large' style={iconColour(4)}/>
                </div>
                <Grid container spacing={1} style={{alignItems: 'center', justifyContent: 'center'}}>
                    {
                        questions[activeQuestion][0] ?
                            textItem(questions[activeQuestion][0]) : questionItem()
                    }
                    {
                        questions[activeQuestion][0] && questions[activeQuestion][1] ?
                            questionItem() : null
                    }
                    {
                        questions[activeQuestion][1] ?
                            textItem(questions[activeQuestion][1]) : questionItem()
                    }
                </Grid>
                <SubmitButton color='secondary' onClick={handleNextQuestion} disabled={!answer}>
                    {t('nextQuestion')}
                </SubmitButton>
            </> : null
    );
}
