import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {useForm} from 'react-hook-form';
import {useStyles} from '../../utils/styling';
import TextFieldComponent from '../../components/common/TextFieldComponent';
import {apiService, ENDPOINTS} from '../../services/api';
import {useTranslation} from 'react-i18next';
import {validatePassword} from '../../utils/passwordValidation';
import BaseForm from '../../components/common/BaseForm';
import SubmitButton from '../../components/common/SubmitButton';
import {readError} from '../../utils/utils';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Errors from '../../components/common/Errors';

export default function PasswordChange({match}) {
    const classes = useStyles();
    const {t} = useTranslation('password-change');
    const [loading, setLoading] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [error, setError] = useState({});

    const form = useForm();

    const onSubmit = data => {
        if (validatePassword(data.password, data.repeatPassword, t, form)) {
            setLoading(true);
            apiService.post(ENDPOINTS.USER.CHANGE_PASSWORD, data)
                .then(response => setPasswordChanged(true))
                .catch(reason => {
                    const error = readError(reason);
                    if (error.code === 'Invalid old password') {
                        form.setError('oldPassword', {
                            message: error.statusText
                        });
                    } else {
                        setError(error);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <BaseForm Icon={LockOutlinedIcon} title={t('title')}>
            {passwordChanged ?
                <Grid container>
                    <Grid item>
                        <Alert severity="success">
                            <AlertTitle>{t('passwordChangedTitle')}</AlertTitle>
                            {t('passwordChanged')}
                        </Alert>
                    </Grid>
                </Grid>
                :
                <form className={classes.form} noValidate onSubmit={form.handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <TextFieldComponent
                            name="oldPassword"
                            type="password"
                            label={t('oldPassword')}
                            autoComplete="current-password"
                            required
                            form={form}
                        />
                        <TextFieldComponent
                            name="password"
                            type="password"
                            label={t('password')}
                            autoComplete="current-password"
                            required
                            form={form}
                        />
                        <TextFieldComponent
                            name="repeatPassword"
                            type="password"
                            label={t('repeatPassword')}
                            required
                            form={form}
                        />
                    </Grid>
                    <div className={classes.wrapper}>
                        <Errors onClose={() => setError(undefined)} error={error}/>
                        <SubmitButton classes={classes} loading={loading}>
                            {t('submit')}
                        </SubmitButton>
                    </div>
                </form>
            }
        </BaseForm>
    );
}