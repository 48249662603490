import React, {useEffect, useState} from 'react';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from 'react-i18next';

export default function ({error, onClose}) {
    const {t} = useTranslation('api-errors');
    const [message, setMessage] = useState();

    useEffect(() => {
        setMessage(error?.statusText);
    }, [error]);

    return (
        <>
            {
                message ?
                    <Alert variant="outlined" severity='error' onClose={onClose}>
                        <AlertTitle>{t('title')}</AlertTitle>
                        {message}
                    </Alert>
                    :
                    null
            }
        </>
    );
}