import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Avatar from "@material-ui/core/Avatar";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fbbf1b',
  },
  body: {
    fontSize: 14,
  },
  footer: {
    backgroundColor: '#ffdc4c',
    fontSize: 14,
    fontWeight: 'bold'
  },
}))(TableCell);

export default function ({t, results}) {

  return (
    results ?
      <>
        <h2>{t('resultsTableTitle')}</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('fullName')}</StyledTableCell>
                <StyledTableCell>{t('resultsTableAllPoints')}</StyledTableCell>
                <StyledTableCell>{t('resultsTableRank')}</StyledTableCell>
                <StyledTableCell>{t('resultsTableQualified')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                results && results.length ?
                  results.map(result =>
                    <TableRow key={result.id}>
                      <StyledTableCell>{result.participant.fullName}</StyledTableCell>
                      <StyledTableCell>{result.allPoints}</StyledTableCell>
                      <StyledTableCell>
                        {result.place}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Avatar style={{backgroundColor: 'transparent'}}>
                          {
                            result.qualifiedForFinal ?
                              <CheckCircleIcon style={{color: 'green'}}/> :
                              <RemoveCircleIcon style={{color: 'red'}}/>
                          }
                        </Avatar>
                      </StyledTableCell>
                    </TableRow>
                  ) : <h2>{t('resultsTableNoParticipants')}</h2>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </> : null
  );
}
