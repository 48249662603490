import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import ExtensionIcon from '@material-ui/icons/Extension';
import BrushIcon from '@material-ui/icons/Brush';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import {makeStyles} from '@material-ui/core/styles';
import {blue} from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    }
});

export default function ({category, categoryLabel = '', t}) {

    const classes = useStyles();
    const tooltip = categoryLabel || t(`category_${category}`);

    const categoryToIcon = () => {
        switch (category) {
            case 'puzzle':
                return <ExtensionIcon/>;
            case 'coloring':
                return <BrushIcon/>;
            default:
                return <BubbleChartIcon/>;
        }
    };

    return (
        <Tooltip title={tooltip} aria-label={tooltip}>
            <Avatar className={classes.avatar}>
                {categoryToIcon()}
            </Avatar>
        </Tooltip>
    );
}