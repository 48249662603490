import React from "react";
import SchoolDetails from "../ParticipantRegistration/SchoolDetails";
import {useHistory} from 'react-router-dom';
import UserDetails from "../ParticipantRegistration/UserDetails";
import MuiAlert from '@material-ui/lab/Alert';
import QualificationInfo from "./QualificationInfo";
import MyPosition from "../MyFinalResults/MyPosition";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ({t, results, endDate, school, category}) {

  const history = useHistory();

  return (
    <>
      <QualificationInfo t={t} results={results}/>
      {/*<p style={{textAlign: 'center'}}>{t('timeLeft')}</p>*/}
      {/*{!!endDate ?*/}
      {/*    <Countdown date={endDate} t={t}/> : null}*/}
      <UserDetails/>
      {
        category ?
          <SchoolDetails t={t} school={school} category={category} title={t('schoolDetailsTitle')}/>
          :
          <>
            <p>{t('youHaveNotRegisteredYet')}</p>
            {/*<SubmitButton onClick={() => history.push(ROUTES.CONTEST.PARTICIPANT.REGISTER_NO_CODE)}*/}
            {/*              color='secondary'>*/}
            {/*    {t('registerNowButton')}*/}
            {/*</SubmitButton>*/}
          </>
      }
      <MyPosition/>
    </>
  );
}
