import React, {useState} from "react";
import TextFieldComponent from "../../../components/common/TextFieldComponent";

export default function ({t, currentAnswer, name, label, form, ...rest}) {

    const [answer, setAnswer] = useState(currentAnswer);
    const regexp = /^[\d\+=*-]*$/i;

    return (
        <>
            <TextFieldComponent
                name={name}
                label={label}
                validationRegexp={regexp}
                validationRegexpMessage={t('answerValidationMessage')}
                defaultValue={answer}
                form={form}
                // onChange={event => {
                //     if (event.target.value === '' || regexp.test(event.target.value)) {
                //         setAnswer(event.target.value);
                //     }
                // }}
                {...rest}
            />
        </>
    );
}
