export const ROUTES = {
    ROOT: '/',
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    SIGN_OUT: '/sign-out',
    MY_EVENTS: '/my-events',
    REGISTER_SCHOOL: '/school-registration',
    EDIT_EVENT: (event = ':event') => '/school-registration/:event'.replace(':event', event),
    CHANGE_PASSWORD: '/change-password',
    PUZZLES_LIBRARY: '/puzzles-library',
    MY_PROFILE: '/my-profile',
    ACCOUNT_RECOVERY: '/account-recovery',
    ACCOUNT_CONFIRMATION: '/account-confirmation/:token',
    PASSWORD_RESET: '/password-reset/:token',
    LOCAL_ORGANIZERS: '/local-organizers',
    COUNTRY_LOCAL_ORGANIZERS: '/local-organizers/:countryCode',
    LOCAL_ORGANIZERS_ARCHIVE: '/archive/local-organizers/:year',
    COUNTRY_LOCAL_ORGANIZERS_ARCHIVE: '/archive/local-organizers/:year/:countryCode',
    EXAM_ONLINE: '/exam',
    ADMIN: {
        ROOT: '/admin',
        EVENTS: '/admin/events',
        CONTEST: '/admin/contest',
        IMPERSONATE: '/admin/impersonate'
    },
    CONTEST: {
        MY_PROFILE: '/contest/my-profile',
        ORGANIZER: {
            SCHOOL_LIST: '/contest/schools',
            SCHOOL_CATEGORIES: (eventId = ':eventId') => '/contest/schools/:eventId'.replace(':eventId', eventId),
        },
        PARTICIPANT: {
            MY_CONTEST: '/contest',
            MY_RESULTS: '/contest/my-results',
            FINAL_RESULTS: '/contest/final-results',
            REGISTER_NO_CODE: '/contest/register/',
            REGISTER: (accessCode = ':accessCode') => '/contest/register/:accessCode'.replace(':accessCode', accessCode)
        }
    }
};
