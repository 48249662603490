import {apiService, ENDPOINTS} from './api';

const giveLicense = (events) => {
    return apiService.post(ENDPOINTS.ADMIN.GIVE_LICENSE, events);
};


const updateEvent = (eventId, event) => {
    return apiService.put(ENDPOINTS.REGISTRATION.AUTH.BY_ID(eventId), event);
};

const deleteEvent = (eventId) => {
    return apiService.delete(ENDPOINTS.ADMIN.REGISTRATION_BY_ID(eventId));
};

const sendEmail = (eventId, templateId, recipientType) => {
    return apiService.post(ENDPOINTS.ADMIN.SEND_EMAIL(eventId), {
        template: templateId,
        recipient: recipientType
    });
};

const clearGoogleId = (eventId) => {
    return apiService.post(ENDPOINTS.ADMIN.CLEAR_GOOGLE_ID(eventId));
};

const myEventsCall = () => {
    return apiService.get(ENDPOINTS.REGISTRATION.AUTH.ROOT);
}

const myEventsFromPreviousYearCall = (year) => {
    return apiService.get(ENDPOINTS.REGISTRATION.AUTH.BY_YEAR(year));
}

const getEventCall = (eventId) => {
    return apiService.get(ENDPOINTS.REGISTRATION.AUTH.BY_ID(eventId), eventId);
};

const EventsService = {
    giveLicense: giveLicense,
    updateEvent: updateEvent,
    delete: deleteEvent,
    sendEmail: sendEmail,
    myEvents: myEventsCall,
    myEventsFromPreviousYear: myEventsFromPreviousYearCall,
    getEvent: getEventCall,
    clearGoogleId: clearGoogleId
};

export default EventsService;
