import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import {get} from 'react-hook-form';

export default function ({form, t}) {

    const consent = t('coordinatorConsent');
    const error = get(form.errors, 'coordinatorConsent');

    return (
        !!consent ?
            <>
                <FormControlLabel
                    error={!!error}
                    control={
                        <Checkbox
                            name="coordinatorConsent"
                            color="primary"
                            required
                            inputRef={form.register({required: true})}
                        />
                    }
                    label={consent}
                />
                {!!error ?
                    <FormHelperText error>{error ? t('requiredValidationMessage') : undefined}</FormHelperText> : null}
            </> : null
    );
}