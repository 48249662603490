import Cookies from 'universal-cookie/es6';
import jwtDecode from 'jwt-decode';
import {apiService} from "./api";

const COOKIE_MAX_AGE = 60 * 24 * 10;
const cookies = new Cookies();

const setAuthorizationHeader = (authorization) => {
    apiService.defaults.headers['Authorization'] = authorization;
};

const getAuthorizationBearer = () => {
    return cookies.get('authorization');
};

const getAuthorizationToken = () => {
    const bearer = getAuthorizationBearer();
    return bearer?.replace('Bearer ', '');
};


const userAuthenticated = (authorizationHeader) => {
    cookies.set('authorization', authorizationHeader, {path: '/', maxAge: COOKIE_MAX_AGE});
    setAuthorizationHeader(authorizationHeader);
};

const signOut = () => {
    cookies.set('authorization', '', {path: '/'});
    // for some reason remove does not work for me, hence I'm setting it to null
    cookies.remove('authorization');
    setAuthorizationHeader(undefined);
};

const getAuthenticatedUser = () => {
    const authorizationToken = getAuthorizationToken();
    if (!!authorizationToken) {
        const jwtObject = jwtDecode(authorizationToken);
        return jwtObject.sub;
    }
    return null;
};

const isAuthenticated = () => {
    return !!getAuthorizationBearer();
};

export {
    userAuthenticated,
    isAuthenticated,
    signOut,
    getAuthenticatedUser
};
