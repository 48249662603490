import TextField from '@material-ui/core/TextField';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {get} from 'react-hook-form';


export default function EmailFieldComponent({name, required = false, form, ...rest}) {

    const {t} = useTranslation();

    const error = form ? get(form.errors, name) : undefined;

    const errorMessage = (error) => {
        if (error === undefined)
            return null;
        switch (error.type) {
            case 'required':
                return t('requiredValidationMessage');
            case 'pattern':
                return t('wrongEmailFormat');
            default:
                return null;
        }
    };

    return (
        <TextField
            id={name}
            name={name}
            label={t(name)}
            required={required}
            error={error !== undefined}
            helperText={error ? (error.message || errorMessage(error)) : undefined}
            inputRef={form ? form.register({
                    required: required,
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    }
                }
            ) : null}
            {...rest}
        />
    );
}
