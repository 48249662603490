import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from "@material-ui/core/TableFooter";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Avatar from "@material-ui/core/Avatar";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fbbf1b',
  },
  body: {
    fontSize: 14,
  },
  footer: {
    backgroundColor: '#ffdc4c',
    fontSize: 14,
    fontWeight: 'bold'
  },
}))(TableCell);

export default function ({t, question, answers, results}) {

  return (
    answers ?
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">{t('resultsQuestion')}</StyledTableCell>
              <StyledTableCell align="right">{t('resultsYourAnswer')}</StyledTableCell>
              <StyledTableCell align="right">{t('resultsCorrectAnswer')}</StyledTableCell>
              <StyledTableCell align="right">{t('resultsStatus')}</StyledTableCell>
              <StyledTableCell align="right">{t('resultsPoints')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2, 3, 4, 5, 6].map(row =>
              <TableRow key={row}>
                <StyledTableCell component="th" scope="row">
                  {row}
                </StyledTableCell>
                <StyledTableCell align="right">{answers[`answer${row}`]}</StyledTableCell>
                <StyledTableCell align="right">{question[`validAnswer${row}`]}</StyledTableCell>
                <StyledTableCell align="right">
                  <Avatar style={{backgroundColor: 'transparent'}}>
                    {
                      results[`day${question.dayNumber}Answer${row}`] > 0 ?
                        <CheckCircleIcon style={{color: 'green'}}/> :
                        <RemoveCircleIcon style={{color: 'red'}}/>
                    }
                  </Avatar>
                </StyledTableCell>
                <StyledTableCell align="right">
                  {results[`day${question.dayNumber}Answer${row}`] || 0}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell colSpan={4}>{t('resultsAllPoints')}</StyledTableCell>
              <StyledTableCell colSpan={1}>
                {
                  results[`day${question.dayNumber}Points`] || 0
                }
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer> : null
  );
}
