import TextField from '@material-ui/core/TextField';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {get} from 'react-hook-form';


export default function ({name, required = false, form, ...rest}) {

    const {t} = useTranslation();
    const error = get(form.errors, name);

    const errorMessage = (error) => {
        if (error === undefined)
            return null;
        switch (error.type) {
            case 'required':
                return t('requiredValidationMessage');
            case 'pattern':
                return t('wrongPhoneFormat');
            default:
                return null;
        }
    };

    return (
        <TextField
            id={name}
            name={name}
            label={t(name)}
            required={required}
            error={error !== undefined}
            helperText={error ? (error.message || errorMessage(error)) : undefined}
            inputRef={form.register({
                    required: required,
                    pattern: {
                        value: /^[0-9+\-() ]+$/i
                    }
                }
            )}
            {...rest}
        />
    );
}