import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../../services/auth';
import {ROUTES} from '../../utils/navigation';

/*
* Anonymous route is a root that only not authenticated users can access.
* If a person is authenticated then will be redirected to '/'
*/
export default function AnonymousRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => !isAuthenticated()
                ? <Component {...props} />
                : <Redirect to={{pathname: ROUTES.ROOT, state: {from: props.location}}}/>}
        />
    );
}