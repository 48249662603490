import {createMuiTheme, makeStyles} from '@material-ui/core/styles';

const theme = createMuiTheme({
    root: {
        flexGrow: 1,
    },
    typography: {
      fontSize: 24
    },
    props: {
        MuiTextField: {
            'variant': 'outlined',
            'fullWidth': true,
            'margin': 'normal',
            'autoComplete': 'off'
        },
        MuiButton: {
            'fullWidth': true
        },
        MuiAutocomplete: {
            'fullWidth': true
        }
    },
    overrides: {
        MuiAvatar: {
            colorDefault: {
                backgroundColor: '#3f51b5'
            }
        },
        MuiTableCell: {
            root: {
                backgroundColor: 'transparent'
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3), //1
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    rowHeader: {
        backgroundColor: "#fed847"
    },
    contentCentered: {
        justifyContent: "center"
    }
}));

export {theme, useStyles};
