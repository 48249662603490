import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CategoryRow from "./CategoryRow";

export default function ({t, school, categories}) {

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell>{t('categoryName')}</TableCell>
            <TableCell>{t('accessCode')}</TableCell>
            <TableCell>{t('copyLinkAction')}</TableCell>
            <TableCell>{t('numberOfParticipants')}</TableCell>
            <TableCell>{t('action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category, index) => (
            <CategoryRow t={t} key={`row-${index}`} school={school} category={category}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
