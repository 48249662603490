import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {useForm} from 'react-hook-form';
import {useStyles} from '../../utils/styling';
import TextFieldComponent from '../../components/common/TextFieldComponent';
import EmailFieldComponent from '../../components/common/EmailFieldComponent';
import {apiService, ENDPOINTS} from '../../services/api';
import {useTranslation} from 'react-i18next';
import {validatePassword} from '../../utils/passwordValidation';
import SubmitButton from '../../components/common/SubmitButton';
import {readError} from '../../utils/utils';
import AccountConsent from './AccountConsent';
import {useSnackbar} from "notistack";
import AutcompleteSelect from "../../components/common/AutcompleteSelect";

export default function ({consents = [], onSignUpCallback}) {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation('sign-up');
  const [loading, setLoading] = useState(false);
  const [selectedAccountType, setSelectedAccountType] = useState('ADULT');

  const accountTypeOptions = [
    // {
    //   value: 'STUDENT',
    //   label: t('studentAccount')
    // },
    {
      value: 'ADULT',
      label: t('adultAccount')
    }];

  const form = useForm();

  const onSubmit = data => {
    if (validatePassword(data.password, data.repeatPassword, t, form)) {
      setLoading(true);
      apiService.post(ENDPOINTS.USER.SIGN_UP, data)
        .then(response => onSignUpCallback(data))
        .catch(reason => {
          const error = readError(reason);
          if (error.code === 'User already exists') {
            form.setError('email', {
              message: error.statusText
            });
          } else {
            enqueueSnackbar(error?.statusText, error);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const handleAccountTypeChange = (value) => {
    setSelectedAccountType(value.value);
  };

  return (
    <form className={classes.form} noValidate onSubmit={form.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <TextFieldComponent
          name="fullName"
          required autoFocus
          form={form}
        />
        <EmailFieldComponent
          name="email"
          required
          form={form}
        />
        <AutcompleteSelect
          label={t('accountType')}
          form={form}
          name='accountType'
          defaultValue={accountTypeOptions[0].value}
          required
          onValueChange={handleAccountTypeChange}
          selectOptions={accountTypeOptions}/>
        {
          selectedAccountType === 'STUDENT' ?
            <>
              <TextFieldComponent form={form}
                                  required={true}
                                  name='className'
                                  label={t('className')}/>
              <TextFieldComponent form={form}
                                  required={true}
                                  name='yearOfBirth'
                                  label={t('yearOfBirth')}
                                  validationRegexp={/^([2][0][01][0-9])|([1][89][0-9][0-9])$/i}
                                  validationRegexpMessage={t('yearOfBirthValidation')}/>
            </> : null
        }
        <TextFieldComponent
          name="password"
          type="password"
          required
          form={form}
        />
        <TextFieldComponent
          name="repeatPassword"
          type="password"
          required
          form={form}
        />
      </Grid>
      <Grid container spacing={2}>
        <AccountConsent form={form} t={t}/>
      </Grid>
      <br></br>
      {consents.map((item, index) =>
        (<Grid container spacing={2}>
          <item.component name={item.name} form={form}/>
        </Grid>)
      )}
      <div className={classes.wrapper}>
        <SubmitButton loading={loading} classes={classes}>
          {t('submit')}
        </SubmitButton>
      </div>
    </form>
  );
}
