import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {apiService, ENDPOINTS} from '../../services/api';
import {Trans, useTranslation} from 'react-i18next';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../utils/navigation';
import BaseForm from '../../components/common/BaseForm';
import {readError} from '../../utils/utils';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Errors from '../../components/common/Errors';

export default function AccountConfirmation({match}) {
    const {t} = useTranslation('account-confirmation');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [activated, setActivated] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiService.get(ENDPOINTS.USER.ACCOUNT_CONFIRMATION + match.params.token)
            .then(response => setActivated(true))
            .catch(reason => {
                setError(readError(reason));
            })
            .finally(() => setLoading(false));
    }, [match]);

    return (
        <BaseForm title={t('title')} Icon={AccountCircleOutlinedIcon}>
            {loading ? <CircularProgress/> :
                <Grid container>
                    <Grid item>
                        <Errors onClose={() => setError(undefined)} error={error}/>
                        {activated ?
                            <Grid container>
                                <Grid item>
                                    <Alert severity="success">
                                        <AlertTitle>{t('accountActivatedTitle')}</AlertTitle>
                                        <Trans t={t} i18nKey="accountActivated">
                                            Account activated <Link to={ROUTES.SIGN_IN}>Login</Link>
                                        </Trans>
                                    </Alert>
                                </Grid>
                            </Grid>
                            : null
                        }
                    </Grid>
                </Grid>
            }
        </BaseForm>
    );
}