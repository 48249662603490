import React from "react";
import Alert from "@material-ui/lab/Alert";

export default function ({results, t}) {

  return (
    results && results.qualifiedForFinal ?
      <Alert severity="success">{t('qualified', {points: 72})}</Alert> :
      <Alert severity="info">{t('notQualified', {points: results.allPoints, place: results.place})}</Alert>
  );
}
