import React, {useEffect, useState} from "react";
import Countdown from "../../../components/Countdown";
import {getLanguage} from "../../../utils/i18n";
import {Trans} from "react-i18next";
import AnswerField from "./AnswerField";
import {useForm} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import SubmitButton from "../../../components/common/SubmitButton";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {useStyles} from "../../../utils/styling";
import ContestService from "../../../services/ContestService";
import {useSnackbar} from "notistack";
import {error, success} from "../../../hoc/GlobalSnackbar/GlobalSnackbar";
import {readError} from "../../../utils/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import CategoryInfo from "../ContestSchoolDetails/CategoryInfo";
import Paper from "@material-ui/core/Paper";
import AnswersTable from "./AnswersTable";


export default function ({results, currentTime, school, category, question, t, reloadDataHandler}) {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [answers, setAnswers] = useState({});
  const form = useForm();
  const {enqueueSnackbar} = useSnackbar();
  const [registeredAt, setRegisteredAt] = useState('');

  useEffect(() => {
    if (answers?.registeredAt) {
      try {
        setRegisteredAt(new Date(answers.registeredAt).toISOString().replaceAll(/[TZ]/g, ' '));
      } catch (err) {
        setRegisteredAt(new Date(answers.registeredAt).toISOString());
      }
    }
  }, [answers]);

  const calculateDates = (startTime, endTime) => {
    const now = currentTime || new Date().getTime();
    setStartDate(now < startTime ? new Date(startTime) : undefined);
    setEndDate(now >= startTime && now < endTime ? new Date(endTime) : undefined);
    setInProgress(now >= startTime && now < endTime);
    setIsFinished(now > endTime);
  };

  useEffect(() => {
    if (question) {
      calculateDates(question.startDate, question.endDate);
      setLoading(true);
      ContestService.getMyAnswers(question.id)
        .then(response => setAnswers(response.data))
        .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
        .finally(() => setLoading(false));
    }
  }, [question]);

  useEffect(() => form.reset(answers), [answers]);

  const handleDayStarted = () => {
    calculateDates(question.startDate, question.endDate);
    reloadDataHandler();
  }

  const handleDayEnded = () => {
    calculateDates(question.startDate, question.endDate);
    reloadDataHandler();
  }

  const questionLink = () => {
    return question && question.link && question.link.replace('{lang}', getLanguage());
  };

  const answerLink = () => {
    return question && question.answersLink && question.answersLink.replace('{lang}', getLanguage());
  };

  const onSubmit = (data) => {
    setSaving(true);
    ContestService.saveMyAnswers(question.id, data)
      .then(response => {
        enqueueSnackbar(t('answersRegistered'), success);
        setAnswers(response.data);
      })
      .catch(reason => {
        const issue = readError(reason);
        let message = t('errorSavingAnswers');
        if (issue.code === 'Contest time constraints') {
          message = issue?.statusText;
        } else {
          message += issue?.statusText;
        }
        enqueueSnackbar(message, error)
      })
      .finally(() => setSaving(false));
  };

  return (
    loading && answers ? <CircularProgress/> :
      <>
        {startDate ? <>
          <p style={{textAlign: 'center'}}>{t('timeToDayStart')}</p>
          <Countdown currentTime={currentTime} date={startDate} t={t} onTimeIsUp={handleDayStarted}/>
        </> : null}
        {endDate ? <>
          <p style={{textAlign: 'center'}}>{t('timeToDayEnd')}</p>
          <Countdown currentTime={currentTime} date={endDate} t={t} onTimeIsUp={handleDayEnded}/>
        </> : null}
        {
          inProgress || !isFinished ?
            <Alert
              severity="warning">
              {t('categoryCheck', {category: category.name})}<br></br>
              <CategoryInfo country={school.country} category={category.name}/>
            </Alert> : null
        }
        {
          registeredAt ? <Alert
            severity="success">{t('answersRegisteredAtInfo', {date: registeredAt})}</Alert> : null
        }
        {
          inProgress || isFinished ?
            <>
              <Grid container spacing={1} style={{marginTop: '10px', marginBottom: '10px'}}>
                <Grid item xs={6}>
                  <Paper elevation={0}>
                    <form action={questionLink()} method="get" target="_blank" rel='noopener noreferrer'>
                      <SubmitButton color='primary'>
                        {t('getQuestionsButton')}
                      </SubmitButton>
                    </form>
                  </Paper>
                </Grid>
                {
                  answerLink() &&
                  <Grid item xs={6}>
                    <Paper elevation={0}>
                      <form action={answerLink()} method="get" target="_blank" rel='noopener noreferrer'>
                        <SubmitButton color='secondary'>
                          {t('getAnswersButton')}
                        </SubmitButton>
                      </form>
                    </Paper>
                  </Grid>
                }
              </Grid>
            </> : null
        }
        {
          (inProgress || !isFinished || !results) ? null :
            <AnswersTable t={t} question={question} answers={answers} results={results}/>
        }
        {inProgress && results?.qualifiedForFinal ? <>
          <Container maxWidth='sm'>
            <div className={classes.paper}>
              <Typography component="h2" variant="h5">
                {t('answersForm')}
              </Typography>
              <p style={{textAlign: 'center'}}>
                <Trans t={t} i18nKey="explanation"
                       components={{b: <b/>}}>
                  Explanation
                </Trans>
              </p>
              <Alert severity="info">{t('informationAboutFinalRank')}</Alert>

              <form style={{width: '100%'}} noValidate onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  {
                    [1, 2, 3, 4, 5, 6].map(item =>
                      <AnswerField form={form}
                                   key={`answer-${item}`}
                                   id={`answer-${item}`}
                                   name={`answer${item}`}
                                   currentAnswer={answers[`answer${item}`]}
                                   label={`${t('question')} ${item}`}
                                   t={t}/>
                    )
                  }
                </Grid>
                <div>
                  <br></br>
                  <SubmitButton loading={saving}>
                    {answers.registeredAt ? t('submitAnswersAgainButton') : t('submitAnswersButton')}
                  </SubmitButton>
                </div>
              </form>
            </div>
          </Container>
        </> : null}
      </>
  );
}
