import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import FaceIcon from '@material-ui/icons/Face';
import SchoolIcon from '@material-ui/icons/School';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CategoryTabPanel from "./CategoryTabPanel";
import ContestService from "../../../services/ContestService";
import {useSnackbar} from "notistack";
import {readError} from "../../../utils/utils";
import {error} from "../../../hoc/GlobalSnackbar/GlobalSnackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import FinalResultsTable from "./FinalResultsTable";
import {Trans, useTranslation} from "react-i18next";
import SubmitButton from "../../../components/common/SubmitButton";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {ROUTES} from "../../../utils/navigation";
import {useHistory} from "react-router-dom";
import BaseForm from "../../../components/common/BaseForm";
import PollIcon from '@material-ui/icons/Poll';
import Alert from "../../../components/common/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function () {
  const classes = useStyles();

  const {t} = useTranslation('contest-final-results');
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const {enqueueSnackbar} = useSnackbar();
  const [results, setResults] = useState([]);
  const history = useHistory();

  useEffect(() => {
    ContestService.finalResults()
      .then(response => setResults(response.data))
      .catch(reason => enqueueSnackbar(readError(reason)?.statusText, error))
      .finally(() => setLoading(false));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getResultsByCategory = (category) => {
    return results.filter(result => result.categoryName === category);
  }

  const handleSeeMyResults = () => {
    history.push(ROUTES.CONTEST.PARTICIPANT.MY_RESULTS);
  };

  return (
    <BaseForm title={t('title')} size='xl' Icon={PollIcon}>
      {
        loading ? <CircularProgress/> :
          <div className={classes.root}>
            <Alert severity='info'>
              <Trans t={t} i18nKey="info"
                     components={{b: <b/>}}>
                info
              </Trans>
            </Alert>
            <div style={{padding: '20px 20px', textAlign: 'center'}}>
              <SubmitButton onClick={handleSeeMyResults} color='secondary' startIcon={<AssessmentIcon/>}
                            style={{maxWidth: '400px'}}>
                {t('seeMyResults')}
              </SubmitButton>
            </div>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="ALFA" icon={<ChildCareIcon/>}/>
                <Tab label="BETA" icon={<InsertEmoticonIcon/>}/>
                <Tab label="GAMMA" icon={<FaceIcon/>}/>
                <Tab label="OPEN" icon={<PeopleAltIcon/>}/>
                <Tab label="TEACHER" icon={<SchoolIcon/>}/>
              </Tabs>
            </AppBar>
            <CategoryTabPanel value={value} index={0}>
              <FinalResultsTable t={t} results={getResultsByCategory('ALFA')} />
            </CategoryTabPanel>
            <CategoryTabPanel value={value} index={1}>
              <FinalResultsTable t={t} results={getResultsByCategory('BETA')} />
            </CategoryTabPanel>
            <CategoryTabPanel value={value} index={2}>
              <FinalResultsTable t={t} results={getResultsByCategory('GAMMA')} />
            </CategoryTabPanel>
            <CategoryTabPanel value={value} index={3}>
              <FinalResultsTable t={t} results={getResultsByCategory('OPEN')} />
            </CategoryTabPanel>
            <CategoryTabPanel value={value} index={4}>
              <FinalResultsTable t={t} results={getResultsByCategory('TEACHER')} />
            </CategoryTabPanel>
          </div>
      }
    </BaseForm>
  );
}
