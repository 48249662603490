import React, {useState} from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {Link as RouterLink} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import BaseForm from '../../components/common/BaseForm';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';
import FormHelperText from '@material-ui/core/FormHelperText';
import {getLanguage} from '../../utils/i18n';
import SignUpForm from "./SignUpForm";
import MarketingConsent from "./MarketingConsent";
import {ROUTES} from "../../utils/navigation";

export default function SignUp() {
    const {t} = useTranslation('sign-up');
    const [createdEmail, setCreatedEmail] = useState();
    const link = `https://kartygrabowskiego.pl/wmtday/2023/docs/wmtday2023_rules_${getLanguage()}.pdf`;

    const onSignUp = data => {
        setCreatedEmail(data.email);
    };

    return (
        <BaseForm title={t('title')} Icon={LockOutlinedIcon}>
            {!!createdEmail ?
                <Grid container>
                    <Grid item>
                        <Alert severity="success">
                            <AlertTitle>{t('accountActivatedTitle')}</AlertTitle>
                            <Trans t={t} i18nKey="accountActivated">
                                Account activated <RouterLink to={ROUTES.SIGN_IN}>Login</RouterLink>
                            </Trans>
                        </Alert>
                        {/*<Alert severity="success">*/}
                        {/*    <AlertTitle>{t('accountCreatedTitle')}</AlertTitle>*/}
                        {/*    {t('accountCreated', {email: createdEmail})}*/}
                        {/*</Alert>*/}
                    </Grid>
                </Grid> :
                <>
                    <SignUpForm consents={[{name: 'marketingConsent', component: MarketingConsent}]}
                                onSignUpCallback={onSignUp}/>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link component={RouterLink} to='/sign-in' variant="body2">
                                {t('alreadyHaveAccount')}
                            </Link>
                        </Grid>
                    </Grid>
                    {!!t('gdpr') ?
                        <Grid container spacing={2}>
                            <br></br>
                            <FormHelperText>
                                <Trans t={t} i18nKey="gdpr">
                                    text <a href={link} target="_blank" rel="noopener noreferrer">text</a>
                                </Trans>
                            </FormHelperText>
                        </Grid> : null}
                </>}
        </BaseForm>
    );
}
