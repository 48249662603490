import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviousSchoolsDialog({open, handleClose, schools, onSelect}) {
  const classes = useStyles();
  const {t} = useTranslation('my-events');
  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('previousYearSelectionDialogTitle')}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {schools?.map(item => (
            <>
              <ListItem button onClick={() => onSelect(item)}>
                <ListItemText primary={`[${item.fullLicenseNumber}] ${item.school.name}`}
                              secondary={item.school.address}/>
              </ListItem>
              <Divider/>
            </>
          ))}
        </List>
      </Dialog>
    </div>
  );
}
