import React, {useEffect, useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextFieldComponent from '../../components/common/TextFieldComponent';
import {getLanguage} from '../../utils/i18n';
import {COUNTRIES} from '../../utils/countries/countries';

export default function ({name, label, form, ...rest}) {
    const [options, setOptions] = useState([]);
    const [countries, setCountries] = useState({});
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        const countriesByLanguage = COUNTRIES[getLanguage()];
        setCountries(countriesByLanguage);
        setOptions(countriesByLanguage.list.map(item => item.label));

        const selected = form.watch(name);
        form.register({'name': name});
        form.setValue(name, selected, {shouldValidate: true});
        setSelectedOption(countriesByLanguage.getByCode(selected)?.label);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event, value) => form.setValue(name, countries.getByName(value)?.code, {shouldValidate: true});

    return (
        (options.length > 0) ?
            <Autocomplete
                id={name}
                {...rest}
                defaultValue={selectedOption}
                options={options}
                onChange={handleChange}
                renderInput={(params) =>
                    <TextFieldComponent {...params}
                                        name={name + 'Name'}
                                        label={label}
                                        {...rest}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                        form={form}/>}
            /> : null
    );
}