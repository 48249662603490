import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {useStyles} from '../../utils/styling';

export default function BaseForm({Icon, title, children, size = 'xs'}) {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth={size}>
            {/*<CssBaseline/>*/}
            <div className={classes.paper}>
                {Icon ?
                    <Avatar className={classes.avatar}>
                        <Icon/>
                    </Avatar> : null}
                {title ?
                    <Typography component="h1" variant="h5">
                        {title}
                    </Typography> : null}
                {children}
            </div>
        </Container>
    );
}