import React, {useEffect, useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextFieldComponent from '../../components/common/TextFieldComponent';

export default function ({name, label, form, selectOptions, onValueChange, ...rest}) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(selectOptions.map(item => item.label));
        form.register({'name': name});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event, value) => {
        const selectedValue = selectOptions.filter(item => item.label === value)[0];
        form.setValue(name, selectedValue.value, {shouldValidate: true})
        onValueChange(selectedValue);
    };

    return (
        (options.length > 0) ?
            <Autocomplete
                id={name}
                {...rest}
                options={options}
                onChange={handleChange}
                renderInput={(params) =>
                    <TextFieldComponent {...params}
                                        name={name + 'Name'}
                                        label={label}
                                        {...rest}
                                        inputProps={{
                                            ...params.inputProps
                                        }}
                                        form={form}/>}
            /> : null
    );
}
